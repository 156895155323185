import React from 'react';
import {Button, Message} from 'semantic-ui-react';
import styles from '../dashboard.module.css';

interface Props {
  isSoundReady: boolean;
  children?: React.ReactNode;
}

interface State {
}

export default class TurnOnSoundButtonComponent extends React.Component<Props, State> {
  render(): any {
    if (this.props.isSoundReady) {
      return null;
    }
    return <Message style={{textAlign: 'center', paddingTop: '10px', paddingBottom: '10px'}}
                    size='big'>
      To turn on audio alerts click anywhere on the website &nbsp;&nbsp;
      <Button
          className={styles.Vibrate}
          content='SOUND ON'
          size='large'
          color='red'
          icon='play'
          labelPosition='right'>
      </Button>
    </Message>;
  }
}

import React, {FormEvent} from 'react';
import {Button, Checkbox, CheckboxProps, Grid, Search, SearchProps, SearchResultData, Segment} from 'semantic-ui-react';
import ProductsTable from './productstable/productstable.component';
import Dashboard, {ProductsState} from '../dashboard/dashboard.component';
import {Product} from '../../model/Product';
import './productstable/productstable.component.css';
import _ from 'lodash';

type ProductsProps = {
  siteId: string;
  productsState: ProductsState;
  openProductStateUpdateErrorModal(product: Product): void;
  closeProductStateUpdateErrorModal(): void;
  handleStockChange(product: Product, handleStockChange: string): void;
  handleSelectedChange(product: Product): void;
  handleSetStock(newSomStockStatus: string): void;
  handleSort(column: string): void;
  handleResultSelect(searchResultData: SearchResultData): void;
  handleSearchChange(searchProps: SearchProps): void;
  handleHideNotAvailableCheckbox(hideNotAvailable: boolean): void;
  dashboardComponent: Dashboard;
};

export const Products = (props: ProductsProps) => {

  const handleResultSelect = (event: React.MouseEvent<HTMLDivElement>, searchResultData: SearchResultData): void => {
    props.handleResultSelect(searchResultData);
  };

  const handleSearchChange = (event: React.MouseEvent<HTMLElement>, searchProps: SearchProps): void => {
    props.handleSearchChange(searchProps);
  };

  const handleHideNotAvailableCheckbox = (checked: boolean | undefined): void => {
    const checkedValue = !!checked;
    console.log(checkedValue);
    props.handleHideNotAvailableCheckbox(checkedValue);
  };

  return (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Segment className={'topSegment'}>
              <Search
                  input={{icon: 'search', iconPosition: 'right'}}
                  loading={props.productsState.isLoading}
                  className={'searchInput'}
                  onResultSelect={handleResultSelect}
                  onSearchChange={_.debounce(handleSearchChange, 500, {leading: true})}
                  results={props.productsState.searchHintProducts.map(s => ({ ...s, key: s.productId }))}
                  value={props.productsState.searchValue}
                  placeholder='Search by name...'
              />
              <Checkbox label={'List without \'Hidden\' products'}
                        className={'hideNotAvailableCheckbox'}
                        checked={props.productsState.hideNotAvailable}
                        onChange={(event: FormEvent<HTMLInputElement>, data: CheckboxProps)  => handleHideNotAvailableCheckbox(data.checked)}
              >
              </Checkbox>
            </Segment>
            {
              props.productsState.bulkSelectedProducts.length > 0 ?
              <Segment inverted color='red'>
                {props.productsState.bulkSelectedProducts.length} items selected
                <Button onClick={() => props.handleSetStock('OUT_OF_STOCK')} negative style={{ marginLeft: '30%' }}>Set to "Out of stock"</Button>
                <Button onClick={() => props.handleSetStock('IN_STOCK')} negative>Set to "In stock"</Button>
                <Button onClick={() => props.handleSetStock('NOT_AVAILABLE')} negative>Set to "Hidden"</Button>
              </Segment>
              : null
            }
            <ProductsTable
                siteId={props.siteId}
                productsState={props.productsState}
                openProductStateUpdateErrorModal={props.openProductStateUpdateErrorModal}
                closeProductStateUpdateErrorModal={props.closeProductStateUpdateErrorModal}
                handleStockChange={props.handleStockChange}
                handleSelectedChange={props.handleSelectedChange}
                handleSort={props.handleSort}
                dashboardComponent={props.dashboardComponent}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
  );
};

export default Products;

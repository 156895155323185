import React from 'react';
import {
  Button,
  Icon,
  Modal,
  Header,
  Input,
  Message,
  InputOnChangeData
} from 'semantic-ui-react';
import {post} from '../../clients/mobile-backend.client';
import {AxiosResponse} from 'axios';
import passwordValidator from 'password-validator';

interface Props {
  isOpened: boolean;
  selectedUser: string;
  closeModal: any;
}

interface State {
  passwordsValidated: boolean;
  passwordOne?: string;
  passwordTwo?: string;
  passwordUpdateError: boolean;
}

class CreateQmsAccountModal extends React.Component<Props, State> {

  state: State = {
    passwordsValidated: false,
    passwordUpdateError: false
  };

  render(): any {
    return (
        <Modal
            open={this.props.isOpened}
        >
          <Modal.Header>QMS account creation for site ({this.props.selectedUser})</Modal.Header>
          <Modal.Content image>
            <Modal.Description>
              <Header>Provide password</Header>
              <p>
                By default, passwords must be a minimum of <strong>ten</strong> characters long and contain at least:
                <ul>
                  <li>one <strong>uppercase</strong> and one <strong>lowercase</strong> letter (A, z),</li>
                  <li>one <strong>numeric</strong> character (0-9),</li>
                  <li>one <strong>special</strong> character (such as: !, %, @, #).</li>
                </ul>
              </p>
              <p>Please provide <strong>new password</strong>:</p>
              <p><Input type='password' onChange={(event, data) => this.handlePasswordOne(data)}/></p>
              <p>Confirm <strong>new password</strong>:</p>
              <p><Input type='password' onChange={(event, data) => this.handlePasswordTwo(data)}/></p>
              <p>
                Passwords meet requirements and match: {this.state.passwordsValidated ?
                  <Icon name='checkmark' color='green'/> :
                  <Icon name='x' color='red'/>}
              </p>
              <p>
                <Button color='green' onClick={() => this.handleUserAccountCreation()} disabled={!this.state.passwordsValidated}>
                  Create
                </Button>
              </p>
              {
                this.state.passwordUpdateError ?
                    <p><Message negative><Message.Header>Password update failed</Message.Header><p>Please try again later :(</p></Message></p> :
                    null
              }
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button color='red' onClick={() => {this.resetFields(); this.props.closeModal(); }}>Close</Button>
          </Modal.Actions>
        </Modal>
    );
  }

  private resetFields = (): void => {
    this.setState({
      passwordOne: undefined,
      passwordTwo: undefined,
      passwordsValidated: false,
      passwordUpdateError: false
    });
  }

  private handleUserAccountCreation = (): void => {
    const url = '/api/v2/users/screen';
    const body = {
      siteId: this.props.selectedUser,
      userLogin: this.props.selectedUser + '_screen',
      password: this.state.passwordTwo,
      deviceToken: localStorage.getItem('deviceToken')};
    post<any>(url, body, undefined)
    .then((response: AxiosResponse) => {
      this.resetFields();
      this.props.closeModal();
    })
    .catch((error: Error) => {
      this.setState({
        passwordUpdateError: true
      });
    });
  }

  private handlePasswordOne = (data: InputOnChangeData): void => {
    this.setState({ passwordOne: data.value });
    if (this.isPasswordValid(data.value) && data.value === this.state.passwordTwo) {
      this.setState({ passwordsValidated: true });
    } else {
      this.setState({ passwordsValidated: false });
    }
  }

  private handlePasswordTwo = (data: InputOnChangeData): void => {
    this.setState({ passwordTwo: data.value });
    if (this.isPasswordValid(data.value) && data.value === this.state.passwordOne) {
      this.setState({ passwordsValidated: true });
    } else {
      this.setState({ passwordsValidated: false });
    }
  }

  private isPasswordValid = (password: string): boolean | string[] => {
    return new passwordValidator()
    .is().min(10)
    .has().uppercase()
    .has().lowercase()
    .has().digits()
    .has().symbols()
    .has().not().spaces()
    .validate(password);
  }

}

export default CreateQmsAccountModal;

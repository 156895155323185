import React from 'react';
import styles from './detailsmodel.module.css';
import feedback from '../../../../assets/announcement-24px.svg';
import {Divider, Icon, List, Modal, Image, SemanticICONS} from 'semantic-ui-react';
import {OrderStatus, PapOrder} from '../../../model/responses/paporder.response';
import Moment from 'react-moment';
import { ProductItems } from '../../productitems/productitems.component';

interface Props {
  details: PapOrder;
  isOpened: boolean;
  closeModal: any;
}

interface State {
  printView: boolean;
}

class OrderDetailsModal extends React.Component<Props, State> {

  state: State = {
    printView: false
  };

  private componentRef: any = React.createRef();

  private showPrintView(visibility: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      this.setState({printView: visibility}, () => resolve());
    });
  }

  private resolveBorderColor(currentStatus: string): string {
    switch (currentStatus) {
      case OrderStatus.NEW:
        return styles.BorderNew;
      case OrderStatus.IN_PROGRESS:
        return styles.BorderInProgress;
      case OrderStatus.READY:
        return styles.BorderReady;
      case OrderStatus.PICKED_UP:
        return styles.BorderPickedUp;
      default:
        return styles.BorderUnknown;
    }
  }

  private resolveBackground(currentStatus: string): string {
    switch (currentStatus) {
      case OrderStatus.NEW:
        return styles.BackgroundNew;
      case OrderStatus.IN_PROGRESS:
        return styles.BackgroundInProgress;
      case OrderStatus.READY:
        return styles.BackgroundReady;
      case OrderStatus.PICKED_UP:
        return styles.BackgroundPickedUp;
      default:
        return styles.BackgroundUnknown;
    }
  }

  private resolveStatusIcon(currentStatus: string): SemanticICONS {
    switch (currentStatus) {
      case OrderStatus.NEW:
        return 'clock outline';
      case OrderStatus.IN_PROGRESS:
        return 'refresh';
      case OrderStatus.READY:
        return 'shopping basket';
      case OrderStatus.PICKED_UP:
        return 'hand paper';
      default:
        return 'clock outline';
    }
  }

  private modalContent = (props: any): React.ReactNode => {
    return (
        <Modal.Content>
          <div className={[this.resolveBorderColor(props.details.status), styles.DetailsContainer].join(' ')}>
            <div className={this.resolveBackground(props.details.status)}>
              <span className={styles.OrderHeaderText}>
                <Icon name={this.resolveStatusIcon(props.details.status)}/>
                {props.details.status}</span>
              <div className={[styles.DetailsContainerIcon, styles.PointerIcon].join(' ')}>
                <Icon
                    size='big'
                    inverted
                    name='remove circle'
                    onClick={props.closeModal}
                />
              </div>
            </div>
            <div className={styles.OrderHeader}>
              <p className={styles.OrderInformation}>Order number / Pick up time</p>
              <span className={styles.OrderNumber}>{props.details.checkNumber} / </span>
              <span className={styles.OrderTime}><Moment format='HH:mm DD/MM'>{props.details.estimatedPickupTime}</Moment></span>
            </div>
            {this.props.details.allergyNotes ? <div className={styles.AllergyNotes}>
                <div className={styles.AllergyNotesHeader}>
                  Allergy note <div style={{ display: 'inline-block', paddingTop: '4px', width: '20px', height: '20px'}}>
                    <Image src={feedback}/>
                  </div>
                </div>
                <div className={styles.AllergyNotesText}>{this.props.details.allergyNotes}</div>
              </div> : <Divider style={{ marginTop: '2%', marginBottom: '2%' }} />}
            <div className={styles.DetailsList}>
              <List divided>
                <ProductItems items={this.props.details.products} styles={styles}/>
              </List>
            </div>
            <Divider style={{marginTop: '2%'}}/>
            <div className={styles.Summary}>
              {
                props.details.user && props.details.user.name ? <div style={{marginBottom: '2%'}}>
                  <p className={styles.SegmentTitleParagraph}>Name</p>
                  <p className={styles.SegmentContentParagraph}>{props.details.user.name}</p>
                </div> : null
              }
              <div>
                <div style={{marginBottom: '2%'}}>
                  <p className={styles.SegmentTitleParagraph}>Pick up method</p>
                  <p className={styles.SegmentContentParagraph}>Pick up in store</p>
                </div>
              </div>
              <div>
                <div style={{marginBottom: '15px'}}>
                  <p className={styles.SegmentTitleParagraph}>Order placed via</p>
                  <p className={styles.SegmentContentParagraph}>{props.details.orderSource}</p>
                </div>
              </div>
              {
                props.details.user && props.details.user.phoneNumber ?
                    <div>
                      <div style={{marginBottom: '2%'}}>
                        <p className={styles.SegmentTitleParagraph}>Phone number</p>
                        <span className={styles.SegmentContentParagraph}>{props.details.user.phoneNumber}</span>
                        <span className={styles.PointerIcon} style={{float: 'right'}}>
                   <Icon name='phone' size='large' color='red'/>
                  </span>
                      </div>
                    </div> : null
              }
              <div style={{marginBottom: '2%'}}>
                <p className={styles.SegmentTitleParagraph}>Order ID</p>
                <span className={styles.SegmentContentParagraph}>{props.details.longId}</span>
                <span className={styles.PointerIcon} style={{float: 'right'}}>
                  <Icon name='copy outline' size='large' color='red'/>
                </span>
              </div>
              {
                this.props.details.comment ?
                  <div style={{marginBottom: '2%'}}>
                  <p className={styles.SegmentTitleParagraph}>Comment</p>
                  <p className={styles.SegmentContentParagraph}>{props.details.comment}</p>
                </div> : null
              }
            </div>
          </div>
        </Modal.Content>
    );
  }
  render(): any {
    return (
        <Modal
            open={this.props.isOpened}
            basic
            closeOnDimmerClick={false}
        >
          {this.props.details ? this.modalContent(this.props) : null}
        </Modal>
    );
  }

}


export default OrderDetailsModal;

import * as React from 'react';
import {ComponentItem, Product} from '../../model/responses/paporder.response';
import {List} from 'semantic-ui-react';
import JsBarcode from 'jsbarcode';

type Props = {
  items: Product[];
  styles: any;
};

type State = {};

export class ProductItems extends React.Component<Props, State> {

  componentDidMount(): void {
    JsBarcode('#barcode').init({ean128: true});
  }

  private isProductNegated(componentItem: ComponentItem): boolean {
    return componentItem?.option === 'REMOVE';
  }

  private renderRegularItems(product: Product): any {
    return product.componentItems?.map(componentItem => {
      return (<div key={(product.uuid || product.id ) + componentItem.componentItems}>
            {this.renderSubComponentItems(componentItem)}
      </div>
      );
    });
  }

  private renderSubComponentItems(componentItem: ComponentItem): any {
    return (<div>
          <List.Description className={ this.isProductNegated(componentItem) ? this.props.styles.DetailsListItemDescriptionStriked : this.props.styles.DetailsListItemDescription}>
            { this.isTypeContainer(componentItem) ? '' : ( (this.isTypeDeposit(componentItem) ? '' : (this.isProductNegated(componentItem) ? '- ' : '+ ')) + componentItem.name) }
            {componentItem.componentItems?.map(componentItem => { return this.renderSubComponentItems(componentItem); })}
          </List.Description>
        </div>
    );
  }

  private isTypeContainer(componentItem : ComponentItem): boolean {
    return componentItem.name.includes('container');
  }

  private isTypeDeposit(componentItem : ComponentItem): boolean {
    return componentItem.name.includes('deposit');
  }

  private renderRegularView(product: Product): any {
    return <List.Item className={this.props.styles.DetailsListItem} key={product.uuid || product.id}>
      <List.Content floated='right'>x {product.quantity}</List.Content>
      <List.Content>
        <List.Header>{product.title}</List.Header>
        <List.Description className={this.props.styles.DetailsListItemDescription}>{product.id}</List.Description>
        {this.renderRegularItems(product)}
      </List.Content>
    </List.Item>;
  }

  render(): any {
    return this.props.items.map(product => {
          return this.renderRegularView(product);
        }
    );
  }

}

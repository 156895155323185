import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './components/App';
// tslint:disable-next-line:no-import-side-effect
import '@fontsource/roboto';

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

import React from 'react';

interface Props {
  children: React.ReactNode;
  disabled: boolean;
  onClick: VoidFunction;
}

export const Arrow = (props: Props) => {
  return (
      <button
          disabled={props.disabled}
          onClick={props.onClick}
          style={{
            backgroundColor: 'transparent',
            color: 'rgba(0,0,0,.87)',
            border: 'none',
            cursor: props.disabled ? 'not-allowed' : 'pointer',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            right: '1%',
            opacity: props.disabled ? '0' : '1',
            userSelect: 'none'
          }}>
        {props.children}
      </button>
  );
};

import React, {useState, useEffect} from 'react';
import {Button} from 'semantic-ui-react';
import styles from './fadeoutcontrollers.module.css';
import {useNavigate} from 'react-router-dom';

const FadeOutControllers: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(true);

  useEffect(() => {
    runHideOutInterval();
  }, []);

  const navigate = useNavigate();

  const logOut = () => {
    localStorage.clear();
    navigate('/');
    window.location.reload();
  };

  const runHideOutInterval = () => {
    let timeoutId: NodeJS.Timeout;

    const handleMouseMove = () => {
      setIsVisible(true);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
    };

    const handleMouseLeave = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, 5000);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseleave', handleMouseLeave);
      clearTimeout(timeoutId);
    };
  };

  return (
      <div className={styles.fadeOutControllers}
           style={{transition: 'opacity 0.5s', opacity: isVisible ? 1 : 0}}>
        <Button
            color='red'
            content='Logout'
            onClick={logOut}
        />
      </div>
  );
};

export default FadeOutControllers;

import React, {SyntheticEvent} from 'react';
import {Checkbox, Dropdown, Label, List, Loader, Pagination, PaginationProps, Segment, Table} from 'semantic-ui-react';
import {Product} from '../../../model/Product';
import ProductStateErrorModal from '../productstateerrormodal/productstateerrormodal.component';
import Dashboard, {ProductsState} from '../../dashboard/dashboard.component';
import './productstable.component.css';

const stockValues = [
  { key: 1, text: 'In stock', value: 'IN_STOCK' },
  { key: 2, text: 'Out of stock', value: 'OUT_OF_STOCK' },
  { key: 3, text: 'Hidden', value: 'NOT_AVAILABLE' }
];

const getDisplayText = (status: string): string => {
  const stockValue = stockValues.find((item) => item.value === status);
  return stockValue ? stockValue.text : status;
};

type Props = {
  siteId: string;
  productsState: ProductsState;
  openProductStateUpdateErrorModal(product: Product): void;
  closeProductStateUpdateErrorModal(): void;
  handleStockChange(product: Product, newSomStockStatus: string): void;
  handleSelectedChange(product: Product): void;
  handleSort(column: string): void;
  dashboardComponent: Dashboard;
};

export const ProductsTable = (props: Props) => {
  const getLoader = () => {
    return (
        <div style={{margin: '0% 50%'}}>
          <Loader active inline content='Loading'/>
        </div>
    );
  };

  const closeProductStateUpdateErrorModal = () => {
    props.closeProductStateUpdateErrorModal();
  };

  const buildRows = (): React.ReactNode => {
    return (
      props.productsState.products?.map((product: Product) => {
        return buildRow(product);
      })
    );
  };

  const buildRow = (product: Product): React.ReactNode => {
    const select: React.ReactNode = resolveCellWithCheckbox('select', product);
    const title: React.ReactNode = resolveCell('title', product.title);
    const productId: React.ReactNode = resolveCell('productId', product.productId);
    const somStockStatus: React.ReactNode = resolveCellWithDropdown('somStockStatus', product);

    return (
      <Table.Row key={product.productId} className={product.somStockStatus === 'NOT_AVAILABLE' ? 'greyText' : undefined}>
        {select}
        {title}
        {productId}
        {somStockStatus}
      </Table.Row>
    );
  };

  const resolveCell = (cellName: string, content: any): React.ReactNode => {
    return (
      <Table.Cell>
        {content}
      </Table.Cell>
    );
  };

  const resolveCellWithDropdown = (cellName: string, product: Product): React.ReactNode => {
    return resolveCell(
      cellName,
      <Dropdown
        text={getDisplayText(product.somStockStatus)}
        options={stockValues}
        closeOnChange
        onChange={(event, data) => handleStockChange(product, data.value as string)}
        value={product.somStockStatus}
      />
    );
  };

  const resolveCellWithCheckbox = (cellName: string, product: Product): React.ReactNode => {
    return resolveCell(
      cellName,
      <Checkbox
        checked={product.selected}
        onChange={() => handleSelectedChange(product)}
      />
    );
  };

  const resolveHeader = (headerTitle: string, sortable: boolean, width?: any): React.ReactNode => {
    return (
      <Table.HeaderCell
        className='center aligned'
        width={width}
        sorted={sortable ? props.productsState.sortColumn === headerTitle ? props.productsState.direction : undefined : undefined}
        onClick={sortable ? handleSort(headerTitle) : null}
      > {headerTitle}
      </Table.HeaderCell>
    );
  };

  const handleStockChange = (product: Product, newSomStockStatus: string) => {
    props.handleStockChange(product, newSomStockStatus);
  };

  const handleSelectedChange = (product: Product) => {
    props.handleSelectedChange(product);
  };

  const handleSort = (columnToSort: string) => (): void => {
    props.handleSort(columnToSort);
  };

  const getProductsOrError = () => {
    const errorPargraph = <p style={{color: 'red', textAlign: 'center', fontWeight: 'bold', fontSize: '16px'}}>
      Error occurred during fetching products.
    </p>;
    return (
        <React.Fragment>
          {props.productsState.isError && !props.productsState.isErrorModalOpened ? errorPargraph : getProducts()}
        </React.Fragment>
    );
  };

  const handlePageChange = (event: SyntheticEvent, data: PaginationProps) => {
    props.productsState.activePage = data.activePage as number;
    props.dashboardComponent.refreshProducts(props.siteId);
  };

  const getProducts = (): React.ReactNode => {
    const selectHeader: React.ReactNode = resolveHeader('Select', false, 1);
    const productHeader: React.ReactNode = resolveHeader('Product', true, 4);
    const productIdHeader: React.ReactNode = resolveHeader('Product ID', true, 4);
    const inStockHeader: React.ReactNode = resolveHeader('Stock', true, 4);

    const rows: React.ReactNode = buildRows();
    return (
        <div>
          <Segment raised>
            <Label  color='blue' ribbon style={{ cursor: 'default' }}>
              Stock Status Legend
            </Label>
            <List relaxed>
              <List.Item>
                <Label color='green' horizontal>
                  In stock
                </Label>
                Product available in store
              </List.Item>
              <List.Item>
                <Label color='red' horizontal>
                  Out of stock
                </Label>
                Product temporarily <strong>not available</strong> in store
              </List.Item>
              <List.Item>
                <Label color='grey' horizontal>
                  Hidden
                </Label>
                Product hidden from product list
              </List.Item>
            </List>
          </Segment>
          <ProductStateErrorModal
              isOpened={props.productsState.isErrorModalOpened}
              closeModal={closeProductStateUpdateErrorModal}
              details={props.productsState.selectedProduct}
          />
          <Table sortable singleLine striped selectable>
            <Table.Header>
              <Table.Row>
                {selectHeader}
                {productHeader}
                {productIdHeader}
                {inStockHeader}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {rows}
            </Table.Body>
          </Table>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan='3'>
                <Pagination
                    activePage={props.productsState.activePage}
                    totalPages={props.productsState.pages}
                    onPageChange={handlePageChange}
                />
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </div>
    );
  };
  return (props.productsState.isLoading ? getLoader() : getProductsOrError());
};

export default ProductsTable;

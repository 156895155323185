import {OrderStatus, PapOrder} from '../../../model/responses/paporder.response';
import styles from './cancelOrderModal.module.css';
import React from 'react';
import { Modal, Icon, Button, SemanticICONS } from 'semantic-ui-react';
import { AxiosResponse } from 'axios';

interface Props {
  order: PapOrder;
  isOpened: boolean;
  closeModal: any;
  removeFromList(id: string): void;
}

interface State {
  printView: boolean;
  isRequestRunning: boolean;
  cancelOrderErrorMessage: string | undefined;
}

class CancelOrderModal extends React.Component<Props, State> {

  state: State = {
    printView: false,
    isRequestRunning: false,
    cancelOrderErrorMessage: undefined
  };

  private resolveBorderColor(currentStatus: string): string {
    switch (currentStatus) {
      case OrderStatus.NEW:
        return styles.BorderNew;
      case OrderStatus.IN_PROGRESS:
        return styles.BorderInProgress;
      case OrderStatus.READY:
        return styles.BorderReady;
      case OrderStatus.PICKED_UP:
        return styles.BorderPickedUp;
      default:
        return styles.BorderUnknown;
    }
  }

  private resolveBackground(currentStatus: string): string {
    switch (currentStatus) {
      case OrderStatus.NEW:
        return styles.BackgroundNew;
      case OrderStatus.IN_PROGRESS:
        return styles.BackgroundInProgress;
      case OrderStatus.READY:
        return styles.BackgroundReady;
      case OrderStatus.PICKED_UP:
        return styles.BackgroundPickedUp;
      default:
        return styles.BackgroundUnknown;
    }
  }

  private resolveStatusIcon(currentStatus: string): SemanticICONS {
    switch (currentStatus) {
      case OrderStatus.NEW:
        return 'clock outline';
      case OrderStatus.IN_PROGRESS:
        return 'refresh';
      case OrderStatus.READY:
        return 'shopping basket';
      case OrderStatus.PICKED_UP:
        return 'hand paper';
      default:
        return 'clock outline';
    }
  }

  private closeModal(): void {
    this.props.closeModal();
  }

  private cancelOrder(props: any): void {
    this.setState({isRequestRunning: true});
    props.order.sendCancelRequest()
    .then((response: AxiosResponse) => {
      this.closeModal();
      this.props.removeFromList(props.order.id);
    })
    .catch((response: { response: { data: { message: string } } }) => {
      this.setState({cancelOrderErrorMessage: response.response.data.message});
      this.setState({isRequestRunning: false});
    });
  }

  render(): any {
    const modalContent = this.props.order ?
        <Modal.Content>
          <div className={[this.resolveBorderColor(this.props.order.status), styles.ModalContentContainer].join(' ')}>
            <div className={this.resolveBackground(this.props.order.status)}>
              <span className={styles.CancelModalHeaderText}>
                <Icon name={this.resolveStatusIcon(this.props.order.status)}/>
                CANCEL ORDER
              </span>
              <div className={[styles.TopRightIcon, styles.PointerIcon].join(' ')}>
                <Icon
                    size='big'
                    inverted
                    name='remove circle'
                    onClick={this.props.closeModal}
                />
              </div>
            </div>
            <div className={styles.CancelModalTextContainer}>
              <span className={styles.CancelModalText}>Do you want to cancel order {this.props.order.checkNumber}?</span>
              <br/>
              <span className={styles.CancelModalText}>Cancelled orders are visible in orders history.</span>
            </div>
            <div className={styles.CancelModalErrorTextContainer}>
              <span className={[styles.CancelModalText, styles.CancelModalErrorText].join(' ')}>
                {this.state.cancelOrderErrorMessage ? `ERROR: ${this.state.cancelOrderErrorMessage}` : null}
              </span>
            </div>
            <div className={styles.ButtonsRow}>
              <Button className={`${styles.Button} ${styles.CloseModalButton}`} basic circular color='red' onClick={this.props.closeModal}>
                I CHANGED MY MIND
              </Button>
              <Button disabled={this.state.isRequestRunning}
                      className={`${styles.Button} ${styles.CancelOrderButton}`} circular color='red' onClick={() => this.cancelOrder(this.props)}>
                CANCEL THIS ORDER
              </Button>
            </div>
          </div>
        </Modal.Content>
        : null;

    return (
        <Modal
            open={this.props.isOpened}
            basic
            closeOnDimmerClick={false}
        >
          {modalContent}
        </Modal>
    );
  }
}

export default CancelOrderModal;

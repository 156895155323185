import React from 'react';
import {Button, Message} from 'semantic-ui-react';
import styles from '../dashboard.module.css';
import {SoundNotificationManager} from '../../activeorders/soundNotificationManager';

interface Props {
  isMuted: boolean;
  isReady: boolean;
  isNewCurrentOrder: boolean;
  mute: () => void;
  children?: React.ReactNode;
  soundManager: SoundNotificationManager;
}

interface State {
  isSoundPlaying: boolean;
  orderPlayingStatus: boolean;
  ariEventPlayingStatus: boolean;
}

export const Items = {
  CURRENT: 'current',
  UPCOMING: 'upcoming',
  CANCELLED: 'cancelled',
  ALL: 'all',
  PRODUCTS: 'products',
  ADMIN: 'admin'
} as const;

export default class NewOrdersNotification extends React.Component<Props, State> {
  state: State = {
    isSoundPlaying: false,
    orderPlayingStatus: false,
    ariEventPlayingStatus: false,
  };

  componentDidUpdate(prevProps: Props): void {
    // Handle Orders Notification Sound
    this.handleOrderSound(prevProps);
  }

  handleOrderSound(prevProps: Props): void {
    const { isReady, isNewCurrentOrder, isMuted, soundManager } = this.props;

    if (!isReady) {
      console.log('Sound manager not ready, skipping sound actions.');
      return;
    }

    console.log('isReady:', isReady, 'isNewCurrentOrder:', isNewCurrentOrder, 'isMuted:', isMuted);

    if (isNewCurrentOrder && !isMuted) {
      console.log('Triggering order sound...');
      soundManager.triggerOrderSound();
    } else {
      console.log('Stopping order sound...');
      soundManager.stopOrderSound();
    }
  }

  private isEmptyOrderLists(): boolean {
    return !this.props.isNewCurrentOrder;
  }

  render(): any {
    if (this.props.isMuted) {
      return null;
    }
    if (this.isEmptyOrderLists()) {
      return null;
    }
    if (!this.props.isReady) {
      return null;
    }
    return <Message style={{textAlign: 'center', paddingTop: '10px', paddingBottom: '10px'}}
                    size='big'
                    warning>
      New order(s) appeared, click to mute notification sound&nbsp;&nbsp;
      <Button
          className={styles.Vibrate}
          content='Mute'
          color='yellow'
          size='large'
          icon='bell'
          labelPosition='right'
          onClick={() => this.props.mute()}/>
    </Message>;
  }
}

import { AxiosResponse } from 'axios';
import React from 'react';
import Moment from 'react-moment';
import { Button, Grid, Icon, Loader, Segment, Table } from 'semantic-ui-react';
import { post } from '../../clients/mobile-backend.client';
import { OrdersHistory } from '../../model/responses/ordershistory';
import { PapOrder } from '../../model/responses/paporder.response';
import { to2DecimalDigits } from '../../services/money.services';
import OrderDetailsModal from './detailsmodal/detailsmodal.component';
import _ from 'lodash';
import styles from './allorders.module.css';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

class AllOrders extends React.Component<any, any> {

  readonly dateFormat: string = 'YYYY-MM-DD';
  readonly now: moment.Moment = moment();
  readonly startOfMonth: moment.Moment = moment().startOf('month');
  readonly endOfMonth: moment.Moment = moment().endOf('month');

  state: any = {
    isDetailsModalOpened: false,
    sortColumn: null,
    sortDirection: null,
    allOrders: [],
    selectedDetails: null,
    error: false,
    isLoading: false,
    ordersCount: null,
    start: this.startOfMonth,
    end: this.endOfMonth
  };

  componentDidMount(): void {
    this.fetchAllOrders();
  }

  private fetchAllOrders = () => {
    this.setState({ isLoading: true });
    const request: ListOrdersRequest = {
      storeId: this.props.siteId,
      startDate: this.state.start.format(this.dateFormat),
      endDate: this.state.end.format(this.dateFormat)
    };

    return post<OrdersHistory>('/api/v2/orders/store', request)
      .then((response: AxiosResponse) => {
        this.setState({
          allOrders: PapOrder.from(response.data.orders),
          error: false,
          isLoading: false,
          ordersCount: response.data.summarization?.ordersCount,
          totalAmount: response.data.summarization?.totalAmount,
          currency: response.data.summarization?.currency
        }, () => {
          this.handleInitialSort('estimatedPickupTime', 'descending');
        });
      })
      .catch((error: Error) => {
        this.setState({
          error: true,
          isLoading: false,
        });
      });
  }

  private closeModal = () => {
    this.setState({ isDetailsModalOpened: false, selectedDetails: null });
  }

  private openDetailsModal = (papOrder: PapOrder) => {
    this.setState({ isDetailsModalOpened: true, selectedDetails: papOrder });
  }

  private handleInitialSort = (columnToSort: string, sortDirection: string): void => {
    this.setState({
      sortColumn: columnToSort,
      allOrders: _.sortBy(this.state.allOrders, [columnToSort]).reverse(),
      sortDirection: sortDirection
    });
  }

  private handleSort = (clickedColumn: string): void => {
    if (this.state.sortColumn !== clickedColumn) {
      this.setState({
        sortColumn: clickedColumn,
        allOrders: _.sortBy(this.state.allOrders, [clickedColumn]),
        sortDirection: 'ascending',
      });

      return;
    }

    this.setState({
      allOrders: this.state.allOrders.reverse(),
      sortDirection: this.state.sortDirection === 'ascending' ? 'descending' : 'ascending',
    });
  }

  private getTableOrError = () => {
    const errorPargraph = <p style={{color: 'red', textAlign: 'center', fontWeight: 'bold', fontSize: '16px'}}>
      Error occurred during fetching orders.
    </p>;
    return (
        <React.Fragment>
          {this.state.error ? errorPargraph : this.getTable()}
        </React.Fragment>
    );
  }

  private getLoader = () => {
    return (
      <div style={{margin: '0% 50%'}}>
        <Loader active inline content='Loading'/>
      </div>
    );
  }

  private resolveHeader = (headerTitle: string, fieldName: string): React.ReactNode => {
    return (
        <Table.HeaderCell
            sorted={this.state.sortColumn === fieldName ? this.state.sortDirection : null}
            onClick={() => this.handleSort(fieldName)}>
        {headerTitle}
        </Table.HeaderCell>
    );
  }

  private handleDateRangePicker = (start: Date, end: Date, label?: string | undefined) => {
    this.setState({
      start: moment(start),
      end: moment(end)
    }, () => {
      this.fetchAllOrders();
    });
  }

  private handleDateRangePickerCancel = (event: Event, picker: any) => {
    this.setState({
      start: this.startOfMonth,
      end: this.endOfMonth
    }, () => {
      this.fetchAllOrders();
    });
  }

  private dateRangePicker = (): React.ReactNode => {
    const label: string = `${moment(this.state.start).format(this.dateFormat)} - ${moment(this.state.end).format(this.dateFormat)}`;
    return <DateRangePicker
        initialSettings={{
          startDate: this.state.start.toDate(),
          endDate: this.state.end.toDate(),
          maxDate: this.now.toDate(),
          showDropdowns: true,
          locale: {
            cancelLabel: 'Clear'
          },
          ranges: {
            'Today': [
              moment().toDate(),
              moment().toDate()
            ],
            'Yesterday': [
              moment().subtract(1, 'days').toDate(),
              moment().subtract(1, 'days').toDate()
            ],
            'This Month': [
              moment().startOf('month').toDate(),
              moment().endOf('month').toDate()
            ],
            'Last Month': [
              moment().subtract(1, 'month').startOf('month').toDate(),
              moment().subtract(1, 'month').endOf('month').toDate()
            ],
            'Last 12 Months': [
              moment().subtract(1, 'years').toDate(),
              moment().toDate()
            ]
          },
        }}
        onCallback={(start, end, label) => this.handleDateRangePicker(start, end)}
        onCancel={(event, picker) => this.handleDateRangePickerCancel(event, picker)}
      >
        <div className={styles.ReportRange}>
          <Button basic color='red' icon labelPosition='left'>
            <Icon name='calendar alternate outline' />{label}
          </Button>
        </div>
      </DateRangePicker>;
  }

  private getTable = (): React.ReactNode => {
    const orderIdHeader: React.ReactNode = this.resolveHeader('Order ID', 'longId');
    const pickUpHourHeader: React.ReactNode = this.resolveHeader('Pick up hour', 'estimatedPickupTime');
    const orderNoHeader: React.ReactNode = this.resolveHeader('Order no.', 'checkNumber');
    const statusHeader: React.ReactNode = this.resolveHeader('Status', 'status');
    const productQuantityHeader: React.ReactNode = this.resolveHeader('Qty.', 'products.length');
    const totalPriceHeader: React.ReactNode = this.resolveHeader('Total', 'payments[0].amount');

    return (
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <Segment>
            <Grid>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <p>Start date - End date</p>
                  {this.dateRangePicker()}
                </Grid.Column>
                <Grid.Column>
                  <p>&nbsp;</p>
                </Grid.Column>
                <Grid.Column>
                  <p>&nbsp;</p>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row columns={3}>
                <Grid.Column>
                  <p>Orders: <span className={styles.SummaryValue}>{this.state.ordersCount}</span></p>
                </Grid.Column>
                <Grid.Column>
                  <p>Total: <span className={styles.SummaryValue}>{to2DecimalDigits(this.state.totalAmount)} {this.state.currency}</span></p>
                </Grid.Column>
                <Grid.Column>
                  <p>&nbsp;</p>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
          <Grid stackable={true}>
            <Grid.Row>
              <Grid.Column>
                <OrderDetailsModal
                    isOpened={this.state.isDetailsModalOpened}
                    closeModal={this.closeModal}
                    details={this.state.selectedDetails}/>
                <Table striped selectable sortable >
                  <Table.Header>
                    <Table.Row>
                      {orderIdHeader}
                      {pickUpHourHeader}
                      {orderNoHeader}
                      {statusHeader}
                      {productQuantityHeader}
                      {totalPriceHeader}
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {this.state.allOrders.map((order: PapOrder) => {
                      return (
                          <Table.Row
                              key={order.id}
                              className={styles.Pointer}
                              onClick={() => this.openDetailsModal(order)}>
                            <Table.Cell singleLine={true}>{order.longId}</Table.Cell>
                            <Table.Cell singleLine={true}><Moment format='YYYY-MM-DD HH:mm'>{order.estimatedPickupTime}</Moment></Table.Cell>
                            <Table.Cell singleLine={true}>{order.checkNumber}</Table.Cell>
                            <Table.Cell singleLine={true}>{order.status}</Table.Cell>
                            <Table.Cell singleLine={true}>{order.products.length}</Table.Cell>
                            <Table.Cell>{this.resolvePaymentInfo(order)}</Table.Cell>
                          </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          </div>
    );
  }

  private resolvePaymentInfo(order: PapOrder) : string {
    return order.payments && order.payments.length > 0 ?
        to2DecimalDigits(order.payments[0].amount) + ' ' + order.payments[0].currency :
        'MISSING PAYMENT';
  }

  private resolveUserName(order: PapOrder) : string {
    return order.user ? order.user.name : '';
  }

  render(): React.ReactNode {
    return (
      <div>
        {this.state.isLoading ? this.getLoader() : this.getTableOrError()}
      </div>
    );
  }

}

export interface ListOrdersRequest {
  storeId: number;
  startDate: string | null;
  endDate: string | null;
}

export default AllOrders;

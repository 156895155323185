import React from 'react';
import {Button, Container, Grid, Icon, Image, Label, Menu, Message, Modal, SearchProps, SearchResultData} from 'semantic-ui-react';
import AllOrders from '../allorders/allorders.component';
import Products from '../products/products.component';
import {PapOrder, PapOrderResponse, PapOrderType} from '../../model/responses/paporder.response';
import {AxiosResponse} from 'axios';
import {get, patch, put} from '../../clients/mobile-backend.client';
import {ActiveOrders} from '../activeorders/activeorders.component';
import {Product} from '../../model/Product';
import _ from 'lodash';
import Admin from '../admin/admin.component';
import PasswordModal from '../passwordmodal/passwordmodal.component';
import {ProductsResponse} from '../../model/responses/products.response';
import NewOrdersNotification from './newOrdersNotification/newordersnotification.component';
import OutOfStockProductsCount from '../../model/responses/outofstockproductscount.response';
import SoundNotificationsV2 from '../activeorders/soundNotificationsV2';
import TurnOnSoundButtonComponent from './turnOnSoundButton/turnOnSoundButton.component';
import styles from './dashboard.module.css';
import QmsControl from '../qmscontrol/qmscontrol.component';
import {StoreConfig} from '../../model/responses/storeconfig';
import {SoundNotificationManager} from '../activeorders/soundNotificationManager';
import AriEventsNotification from '../arievent/ariEventsNotification';

export const Items = {
  CURRENT: 'current',
  ALL: 'all',
  PRODUCTS: 'products',
  QMS: 'qms',
  ADMIN: 'admin'
} as const;

export const HomePageItem: string = Items.PRODUCTS;

export const ActiveOrdersItems: string[] = [Items.CURRENT.toString()];

export const Authorities = {
  ORDER_READ: 'ORDER_READ',
  ORDER_ADMIN: 'ORDER_ADMIN',
  SCREEN: 'SCREEN'
} as const;

export const EmptyOrdersState: OrdersState = {
  orders: [],
  isError: false,
  isLoading: false,
  isNewOrderAppeared: false,
};

const columnNameToFieldName = new Map<string, string>();
columnNameToFieldName.set('Product', 'title');
columnNameToFieldName.set('Product ID', 'productId');
columnNameToFieldName.set('Stock', 'somStockStatus');

type DashboardProps = {
  siteId: string;
  userName: string;
};

type DashboardState = {
  activeItem: string;
  authorities: string[];
  currentOrdersState: OrdersState;
  productsState: ProductsState;
  userModalOpen: boolean;
  isAfterFirstFetch: boolean;
  isReady: boolean;
  isNewOrderSoundNotificationActive: boolean;
  isMuted: boolean;
  isQmsTurnedOn: boolean;
};

class Dashboard extends React.Component<DashboardProps, DashboardState> {

  private soundManager: SoundNotificationManager = new SoundNotificationManager();

  private activeOrdersRefreshHandler?: number;
  private outOfStockCountRefreshHandler?: number;
  private productsRefreshHandler?: number;
  private newOrderSoundNotificationHandler?: number;
  private expiringNewOrderNotificationHandler?: number;
  private defaultActivePage: number = 1;
  private defaultPages: number = 1;

  private HIDE_NA_LOCAL_STORAGE_KEY: string = 'defaultHideNotAvailable';

  state: DashboardState = {
    authorities: [],
    activeItem: HomePageItem,
    userModalOpen: false,
    isAfterFirstFetch: false,
    isReady: false,
    isNewOrderSoundNotificationActive: false,
    isMuted: false,
    isQmsTurnedOn: false,
    currentOrdersState: EmptyOrdersState,
    productsState: {
      products: [],
      bulkSelectedProducts: [],
      isError: false,
      isLoading: false,
      isErrorModalOpened: false,
      sortColumn: 'Product',
      direction: 'ascending',
      selectedProduct: null,
      productsOutOfStockCount: 0,
      searchHintProducts: [],
      activePage: this.defaultActivePage,
      pages: this.defaultPages,
      pageSize: 20,
      hideNotAvailable: localStorage.getItem(this.HIDE_NA_LOCAL_STORAGE_KEY) !== 'false',
      searchTimeout: undefined
    }
  };

  private resolveInitialAuthoritiesAndActiveItem = (): void => {
    const localStorageRoles = JSON.parse(localStorage.getItem('authorities') || `['${Authorities.ORDER_READ}']`);
    if (Array.isArray(localStorageRoles) && localStorageRoles.length) {
      this.setState({authorities: localStorageRoles});
      if (localStorageRoles.includes(Authorities.ORDER_ADMIN)) {
        this.setState({activeItem: Items.ADMIN});
      }
    }
  }

  componentDidMount(): void {
    this.resolveInitialAuthoritiesAndActiveItem();
    const sorting: SortingPreferences = this.resolveSortingPreferences();
    this.setState({
      productsState: {
        ...this.state.productsState,
        sortColumn: sorting.column,
        direction: sorting.direction
      }
    });
    this.triggerSoundAndOrderFetching();
  }

  componentWillUnmount(): void {
    clearInterval(this.activeOrdersRefreshHandler);
    clearInterval(this.productsRefreshHandler);
    clearInterval(this.newOrderSoundNotificationHandler);
    clearInterval(this.expiringNewOrderNotificationHandler);
  }

  componentDidUpdate(prevProps: Readonly<DashboardProps>, prevState: Readonly<DashboardState>, snapshot?: any): void {
    if (this.state.isReady &&
        !ActiveOrdersItems.includes(prevState.activeItem) && ActiveOrdersItems.includes(this.state.activeItem)) {
      this.getAndSetActivePreorders(this.props.siteId);
    }
  }

  private resolveSortingPreferences = (): SortingPreferences => {
    const sort: string | null = localStorage.getItem('sort');
    if (sort !== null) {
      return JSON.parse(sort);
    } else {
      return {
        column: this.state.productsState.sortColumn,
        direction: this.state.productsState.direction
      };
    }
  }

  public refreshProducts(siteId: string): void {
    const currentPage = this.state.productsState.activePage;
    const size = this.state.productsState.pageSize;
    const sortColumn = this.state.productsState.sortColumn;
    const direction = this.state.productsState.direction;
    const search = this.state.productsState.searchValue;
    this.getAndSetProducts(siteId, currentPage, size, sortColumn, direction, search);
  }

  public refreshStateConfig(siteId: string): void {
    get<StoreConfig>(`/api/v2/store/${siteId}/config`)
    .then((response: AxiosResponse) => {
      this.setState({isQmsTurnedOn: response.data.isQmsEnabled});
    })
    .catch((error: Error) => {
      console.log(error);
    });
  }

  //page starts from 1
  private getAndSetProducts(siteId: string,
                            page: number = 1,
                            size: number = 50,
                            sortColumn: string = 'Product',
                            direction: 'ascending' | 'descending' | undefined = 'ascending',
                            search: string | undefined): void {
    this.setState({
      productsState: {
        ...this.state.productsState,
        isLoading: true
      }
    });
    const dirrectionAbbr = direction === 'ascending' ? 'asc' : 'desc';
    let sortColumnUriName = columnNameToFieldName.get(sortColumn);
    let params: any = {
      size: size,
      page: page - 1,
      sort: sortColumnUriName + ',' + dirrectionAbbr
    };
    if (search !== undefined) {
      params.title = encodeURIComponent(search);
    }
    if (this.state.productsState.hideNotAvailable !== undefined) {
      params.hideNotAvailable = this.state.productsState.hideNotAvailable;
    }
    get<Product[]>(`/api/v2/products/store/${siteId}`, params)
    .then((response: AxiosResponse) => {
      const productsResponse: ProductsResponse = response.data;
      const products: Product[] = productsResponse.content;

      const selectedProductIds: string[] = this.state.productsState.bulkSelectedProducts.map(p => p.productId);
      products.forEach(p => {
        if (selectedProductIds.includes(p.productId)) {
          p.selected = true;
        }
      });

      this.setState({
        productsState: {
          ...this.state.productsState,
          products: products,
          searchHintProducts: products,
          isLoading: false,
          isError: false,
          pages: productsResponse.totalPages,
          //endpoints numerates page from 0
          //reduce page number when it is higher than total Pages
          activePage: Math.min(productsResponse.number + 1, productsResponse.totalPages)
        }
      }, () => {
        //refresh if we are not on first page and no products received
        //to go to the page with products
        if (productsResponse.number > 0 && products.length === 0) {
          this.refreshProducts(siteId);
        }
      });
    })
    .catch((error: Error) => {
      this.setState({
        productsState: {
          ...this.state.productsState,
          products: [],
          bulkSelectedProducts: [],
          isError: true,
          isLoading: false,
          selectedProduct: null,
          isErrorModalOpened: false
        }
      });
    });
  }

  private getOutOfStockProductsCount(siteId: string): void {
    get<OutOfStockProductsCount>(`/api/v2/products/store/${siteId}/outOfStock/count`)
    .then((response: AxiosResponse) => {
      const countResponse: OutOfStockProductsCount = response.data;

      this.setState({
        productsState: {
          ...this.state.productsState,
          productsOutOfStockCount: countResponse.outOfStockCount,
        }
      });
    })
    .catch((error: Error) => {
      this.setState({
        productsState: {
          ...this.state.productsState,
          isError: true,
          isLoading: false,
          isErrorModalOpened: false,
          productsOutOfStockCount: 0
        }
      });
    });
  }

  private getAndSetActivePreorders = (siteId: string): void => {
    get<PapOrderResponse[]>(`/api/v2/orders/active/store/${siteId}`)
    .then((response: AxiosResponse) => {
      const currentOrders: PapOrder[] = PapOrder.from(response.data).filter((order: PapOrder) => order.type === PapOrderType.CURRENT);
      this.handleNewlyAppearedOrders(currentOrders, this.state.currentOrdersState, Items.CURRENT);
    })
    .catch((error: Error) => {
      this.setState({
        currentOrdersState: EmptyOrdersState
      });
    })
    .finally(() => {
      this.setState({
        isAfterFirstFetch: true
      });
    });
  }

  private handleNewlyAppearedOrders = (newOrders: PapOrder[], prevCurrentsOrdersState: OrdersState, item: any): void => {
    const newOrderAppeared = this.checkIfNewOrderAppeared(
        prevCurrentsOrdersState.orders.length,
        newOrders.length);

    const didNewOrderAppear = prevCurrentsOrdersState.isNewOrderAppeared || newOrderAppeared;

    const newOrdersState = {
      orders: newOrders,
      isError: false,
      isLoading: false
    };

    switch (item) {
      case Items.CURRENT:
        this.setState({
          isNewOrderSoundNotificationActive: didNewOrderAppear,
          currentOrdersState: {...newOrdersState, isNewOrderAppeared: didNewOrderAppear}
        }, () => {
          if (newOrderAppeared) {
            this.setState({isMuted: false});
          }
        });
        break;
      default:
        this.setState({
          isNewOrderSoundNotificationActive: didNewOrderAppear,
        });
    }
  }

  private handleSetStock = (newSomStockStatus: string) => {
    const body: BulkStockRequest = {
      somStockStatus: newSomStockStatus,
      productIds: this.state.productsState.bulkSelectedProducts.map(p => p.productId)
    };

    let outOfStockCountChange = 0;
    this.state.productsState.bulkSelectedProducts.forEach(product => {
      if (product.somStockStatus !== 'OUT_OF_STOCK' && newSomStockStatus === 'OUT_OF_STOCK') {
        outOfStockCountChange++;
      }
      if (product.somStockStatus === 'OUT_OF_STOCK' && newSomStockStatus !== 'OUT_OF_STOCK') {
        outOfStockCountChange--;
      }
    });
    put<any>(`/api/v2/products/store/${this.props.siteId}/status`, null, body)
    .then(resolve => {
      const products: Product[] = this.state.productsState.products.slice();
      this.state.productsState.bulkSelectedProducts.forEach(bsp => {
        const product: Product | undefined = products.find(p => p.productId === bsp.productId);
        if (product !== undefined) {
          product.somStockStatus = newSomStockStatus;
          product.selected = false;
        }
      });
      const newProductsOutOfStockCount = this.state.productsState.productsOutOfStockCount + outOfStockCountChange;
      this.setState({
        productsState: {
          ...this.state.productsState,
          products: products,
          bulkSelectedProducts: [],
          isError: false,
          productsOutOfStockCount: newProductsOutOfStockCount
        }
      });
    })
    .catch(reject => {
      this.openProductStateUpdateErrorModal(this.state.productsState.bulkSelectedProducts[0]);
    });
  }

  private handleSelectedChange = (product: Product) => {
    product.selected = !product.selected;
    const currentSelection: Product[] = this.state.productsState.bulkSelectedProducts;
    let newSelection: Product[];
    if (product.selected) {
      currentSelection.push(product);
      newSelection = currentSelection.slice();
    } else {
      newSelection = currentSelection.filter(sp => sp.productId !== product.productId);
    }

    this.setState({
      productsState: {
        ...this.state.productsState,
        bulkSelectedProducts: newSelection
      }
    });
  }

  private handleStockChange = (product: Product, newSomStockStatus: string) => {
    const products = this.state.productsState.products;
    const productUpdated: Product = Object.assign({}, product);
    let outOfStockCountChange = 0;
    if (product.somStockStatus !== 'OUT_OF_STOCK' && newSomStockStatus === 'OUT_OF_STOCK') {
      outOfStockCountChange++;
    }
    if (product.somStockStatus === 'OUT_OF_STOCK' && newSomStockStatus !== 'OUT_OF_STOCK') {
      outOfStockCountChange--;
    }
    productUpdated.somStockStatus = newSomStockStatus;

    const index = _.findIndex(this.state.productsState.products, {productId: product.productId});
    if (index !== -1) {
      put<any>(`/api/v2/products/store/${this.props.siteId}/product/${productUpdated.productId}/status/${newSomStockStatus}`)
      .then(resolve => {
        products.splice(index, 1, productUpdated);
        const currentOutOfStockCount = this.state.productsState.productsOutOfStockCount + outOfStockCountChange;
        this.setState({
          productsState: {
            ...this.state.productsState,
            products: products,
            productsOutOfStockCount: currentOutOfStockCount,
            isError: false
          }
        });
      })
      .catch(reject => {
        this.openProductStateUpdateErrorModal(product);
      });
    }
  }

  private openProductStateUpdateErrorModal = (product: Product) => {
    this.setState({
      productsState: {
        ...this.state.productsState,
        isErrorModalOpened: true,
        selectedProduct: product,
        isError: true
      }
    });
  }

  private closeProductStateUpdateErrorModal = () => {
    this.setState({
      productsState: {
        ...this.state.productsState,
        isErrorModalOpened: false,
        selectedProduct: null,
        isError: false
      }
    });
  }

  private saveSortingPreferences = (column: string, direction: 'ascending' | 'descending' | undefined): void => {
    const preferences: SortingPreferences = {column: column, direction: direction};
    localStorage.setItem('sort', JSON.stringify(preferences));
  }

  private handleSort = (columnToSort: string): void => {
    if (this.state.productsState.sortColumn !== columnToSort) {
      this.sort(columnToSort);
    } else {
      this.reverseSort();
    }
  }

  private reverseSort = () => {
    this.setState({
      productsState: {
        ...this.state.productsState,
        direction: this.state.productsState.direction === 'ascending' ? 'descending' : 'ascending'
      }
    }, () => {
      this.saveSortingPreferences(this.state.productsState.sortColumn, this.state.productsState.direction);
      this.refreshProducts(this.props.siteId);
    });
    //this.refreshProducts(this.props.siteId);
  }

  private sort = (column: string, direction?: 'ascending' | 'descending') => {
    const newDirection = direction || this.state.productsState.direction || 'ascending';
    this.setState({
      productsState: {
        ...this.state.productsState,
        sortColumn: column,
        direction: newDirection
      }
    }, () => {
      this.saveSortingPreferences(column, this.state.productsState.direction);
      this.refreshProducts(this.props.siteId);
    });
  }

  private triggerSoundAndOrderFetching = () => {
    this.getAndSetActivePreorders(this.props.siteId);
    this.refreshProducts(this.props.siteId);
    this.refreshStateConfig(this.props.siteId);
    this.getOutOfStockProductsCount(this.props.siteId);
    this.productsRefreshHandler = window.setInterval(() => this.refreshProducts(this.props.siteId), 60000);
    this.activeOrdersRefreshHandler = window.setInterval(() => this.getAndSetActivePreorders(this.props.siteId), 10000);
    this.outOfStockCountRefreshHandler = window.setInterval(() => this.getOutOfStockProductsCount(this.props.siteId), 60000);
  }

  private checkIfNewOrderAppeared = (oldOrderAmount: number = 0, newOrderAmount: number = 0): boolean => {
    return oldOrderAmount < newOrderAmount;
  }

  private switchTab = (item: string) => {
    if (!this.state.isReady) {
      this.triggerSoundAndOrderFetching();
    }
    this.setState({activeItem: item});
  }

  private getActiveContent = () => {
    switch (this.state.activeItem) {
      case Items.CURRENT:
        return this.currentOrdersOrShowButton([Authorities.ORDER_READ, Authorities.SCREEN]);
      case Items.ALL:
        return <AllOrders siteId={this.props.siteId}/>;
      case Items.PRODUCTS:
        return <Products
            siteId={this.props.siteId}
            productsState={this.state.productsState}
            openProductStateUpdateErrorModal={this.openProductStateUpdateErrorModal}
            closeProductStateUpdateErrorModal={this.closeProductStateUpdateErrorModal}
            handleStockChange={this.handleStockChange}
            handleSelectedChange={this.handleSelectedChange}
            handleSetStock={this.handleSetStock}
            handleSort={this.handleSort}
            handleHideNotAvailableCheckbox={this.handleHideNotAvailableCheckbox}
            handleResultSelect={this.handleResultSelect}
            handleSearchChange={this.handleSearchChange}
            dashboardComponent={this}
        />;
      case Items.QMS:
        return <QmsControl siteId={this.props.siteId}/>;
      case Items.ADMIN:
        return <Admin/>;
      default:
        return this.currentOrdersOrShowButton(Authorities.ORDER_READ);
    }
  }

  private handleResultSelect = (searchResultData: SearchResultData): void => {
    this.setState({
      productsState: {
        ...this.state.productsState,
        searchValue: searchResultData.result.title
      }
    }, () => {
      this.refreshProducts(this.props.siteId);
    });
  }

  private handleHideNotAvailableCheckbox = (hideNotAvailable: boolean): void => {
    this.setState({
      productsState: {
        ...this.state.productsState,
        hideNotAvailable: hideNotAvailable
      }
    }, () => {
      localStorage.setItem(this.HIDE_NA_LOCAL_STORAGE_KEY, String(hideNotAvailable));
      this.refreshProducts(this.props.siteId);
    });
  }

  private handleSearchChange = (searchProps: SearchProps): void => {
    if (this.state.productsState.searchTimeout) {
      clearTimeout(this.state.productsState.searchTimeout);
    }
    const re = new RegExp(_.escapeRegExp(searchProps.value), 'i');
    const isMatch = (result: Product) => re.test(result.title);
    this.setState({
      productsState: {
        ...this.state.productsState,
        isLoading: true,
        searchHintProducts: _.filter(this.state.productsState.products, isMatch),
        searchValue: searchProps.value,
        activePage: 1,
        searchTimeout: setTimeout(() => {
          if (this.state.productsState.searchValue === null
              || this.state.productsState.searchValue === undefined
              || this.state.productsState.searchValue?.length < 1) {
            this.setState({
              productsState: {
                ...this.state.productsState,
                isLoading: false,
                searchHintProducts: this.state.productsState.products,
                searchValue: undefined
              }
            });
          }
          this.refreshProducts(this.props.siteId);
        }, 1000)
      }
    });
  }

  private clearAllActiveOrders = () => {
    this.getAndSetActivePreorders(this.props.siteId);
  }

  private currentOrdersOrShowButton = (authority: string | string[]) => {
    if (this.isAllowed(authority)) {

      const currentOrders = <div>
        <ActiveOrders siteId={this.props.siteId}
                      ordersState={this.state.currentOrdersState}
                      getAndSetActivePreorders={this.getAndSetActivePreorders}
                      clearAllActiveOrders={this.clearAllActiveOrders}
                      removeOrderFromActiveAndUpcomingOrdersList={this.removeFromActiveAndUpcomingOrders}/>
      </div>;

      return currentOrders;

    }
  }

  private removeFromActiveAndUpcomingOrders = (orderId: string) => {
    this.setState({
          currentOrdersState: {
            ...this.state.currentOrdersState,
            orders: this.state.currentOrdersState.orders.filter(order => order.id !== orderId)
          }
        }
    );
  }

  public onNewOrderNotificationMuteClick = () => {
    this.setState({
      currentOrdersState: {
        ...this.state.currentOrdersState,
        isNewOrderAppeared: false
      },
      isMuted: true
    });
  }

  private outOfStockNotification = () => {
    if (this.state.productsState.productsOutOfStockCount > 0) {
      return <Message style={{textAlign: 'center', paddingTop: '8px', paddingBottom: '8px'}}
                      size='big'
                      info>
        <Icon name='alarm' size='large'/>
        {this.state.productsState.productsOutOfStockCount} products are out of stock.
        Please update stock in/out information.
      </Message>;
    }
  }

  private isAllowed = (authority: string | string[]): boolean => {
    if (typeof authority === 'string') {
      return this.state.authorities?.includes(authority as string);
    } else if (this.isStringArray(authority)) {
      return this.findCommonElement(authority as string[], this.state.authorities);
    }
    console.log('some error authority');
    return false;
  }

  private isNotAdmin = (): boolean => {
    return !this.state.authorities?.includes('ORDER_ADMIN');
  }

  private renderCurrentMenuItem = (authority: string | string[]) => {
    if (this.isAllowed(authority)) {
      return <Menu.Item name={Items.CURRENT}
                        active={this.state.activeItem === Items.CURRENT} as='a'
                        className={styles.MainMenuItem}
                        onClick={() => this.switchTab(Items.CURRENT)}>
        Current orders
        {this.shouldShowOrdersCounter() && this.state.currentOrdersState.orders.length > 0 ?
            <Label circular color='red' key='red'>{this.state.currentOrdersState.orders.length}</Label> : null}
      </Menu.Item>;
    }
  }

  private shouldShowOrdersCounter = (): boolean => {
    return this.state.isAfterFirstFetch;
  }

  private renderAllMenuItem = (authority: string) => {
    if (this.isAllowed(authority)) {
      return <Menu.Item
          name={Items.ALL}
          className={styles.NormalMenuItem}
          active={this.state.activeItem === Items.ALL} as='a'
          onClick={() => this.switchTab(Items.ALL)}>
        Historical
      </Menu.Item>;
    }
  }

  private renderProductsMenuItem = (authority: string) => {
    if (this.isAllowed(authority)) {
      return <Menu.Item
          name={Items.PRODUCTS}
          className={styles.NormalMenuItem}
          active={this.state.activeItem === Items.PRODUCTS} as='a' onClick={() => this.switchTab(Items.PRODUCTS)}>
        Products
      </Menu.Item>;
    }
  }

  private renderQmsMenuItem = (authority: string) => {
    if (this.isAllowed(authority) && this.state.isQmsTurnedOn) {
      return <Menu.Item
          name={Items.QMS}
          className={styles.NormalMenuItem}
          active={this.state.activeItem === Items.QMS} as='a' onClick={() => this.switchTab(Items.QMS)}>
        QMS
      </Menu.Item>;
    }
  }

  private renderAdminMenuItem = (authority: string) => {
    if (this.isAllowed(authority)) {
      return <Menu.Item
          name={Items.ADMIN}
          style={{fontSize: '14px', flexGrow: 1, alignItems: 'center', justifyContent: 'center'}}
          active={this.state.activeItem === Items.ADMIN} as='a' onClick={() => this.switchTab(Items.ADMIN)}>
        Manage stations
      </Menu.Item>;
    }
  }

  private openUserModal = () => {
    this.setState({userModalOpen: true});
  }

  private closeUserModal = () => {
    this.setState({userModalOpen: false});
  }

  private setAudioAlertsReady = () => {
    if (!this.state.isReady) {
      this.setState({isReady: true});
      SoundNotificationsV2.initializeAudioFiles();
    }
  }

  private findCommonElement = (arr1: string[], arr2: string[]): boolean => {
    for (let i = 0; i < arr1.length; i++) {
      for (let j = 0; j < arr2.length; j++) {
        if (arr1[i] === arr2[j]) {
          return true;
        }
      }
    }
    return false;
  }

  private isStringArray(variable: any): variable is string[] {
    return Array.isArray(variable) && variable.every(item => typeof item === 'string');
  }

  render(): any {
    return (
        <div onClick={this.setAudioAlertsReady}>
          <Grid className='Dashboard'>
            <Menu fixed='top'>
              <Container>
                <Menu.Item header>
                  <Image size='mini' src='./favicon.ico' style={{marginRight: '1.5em'}}/>
                  Circle K
                </Menu.Item>
                {this.renderCurrentMenuItem([Authorities.ORDER_READ, Authorities.SCREEN])}
                {this.renderAllMenuItem(Authorities.ORDER_READ)}
                {this.renderProductsMenuItem(Authorities.ORDER_READ)}
                {this.renderQmsMenuItem(Authorities.ORDER_READ)}
                {this.renderAdminMenuItem(Authorities.ORDER_ADMIN)}
                <Menu.Item position='right'>
                  <Icon name='building' size='large'/>Circle K {this.props.userName}
                </Menu.Item>
                <Menu.Item>
                  <Button
                      color='red'
                      content='Logout'
                      onClick={() => {
                        localStorage.clear();
                        window.location.reload();
                      }}
                  />
                </Menu.Item>
                {
                  this.isAllowed(Authorities.ORDER_ADMIN) ? <Menu.Item>
                    <PasswordModal selectedUser={this.props.siteId}
                                   selfUpdateMode={true}
                                   isQms={false}
                                   isOpened={this.state.userModalOpen}
                                   closeModal={this.closeUserModal}/>
                    <Button icon color='orange' onClick={this.openUserModal}><Icon name='user'/></Button>
                  </Menu.Item> : undefined
                }
              </Container>
            </Menu>
            <Container className='Container'>
              {this.outOfStockNotification()}
              <NewOrdersNotification
                  isMuted={this.state.isMuted}
                  isReady={this.state.isReady}
                  isNewCurrentOrder={this.state.currentOrdersState.orders.length > 0}
                  mute={this.onNewOrderNotificationMuteClick}
                  soundManager={this.soundManager}
              />
              {this.isNotAdmin() && (
                  <AriEventsNotification
                      siteId={this.props.siteId}
                      isReady={this.state.isReady}
                      soundManager={this.soundManager}
                  />
              )}
              {
                this.isNotAdmin() ?
                    <TurnOnSoundButtonComponent
                        isSoundReady={this.state.isReady}>
                    </TurnOnSoundButtonComponent>
                    :
                    undefined
              }
              {this.getActiveContent()}
            </Container>
          </Grid>
        </div>

    );
  }
}

export default Dashboard;

export interface OrdersState {
  orders: PapOrder[];
  isError: boolean;
  isLoading: boolean;
  isNewOrderAppeared: boolean;
}

export interface ProductsState {
  products: Product[];
  bulkSelectedProducts: Product[];
  sortColumn: string;
  direction?: 'ascending' | 'descending' | undefined;
  isLoading: boolean;
  isError: boolean;
  isErrorModalOpened: boolean;
  selectedProduct?: Product | null;
  productsOutOfStockCount: number;
  searchValue?: string;
  searchHintProducts: Product[];
  activePage: number;
  pages: number;
  pageSize: number;
  hideNotAvailable: boolean;
  searchTimeout: NodeJS.Timeout | undefined;
}

export interface BulkStockRequest {
  somStockStatus: string;
  productIds: string[];
}

export interface SortingPreferences {
  column: string;
  direction: 'ascending' | 'descending' | undefined;
}

import React from 'react';
import {Container} from 'semantic-ui-react';
import styles from './qmsscreen.module.css';
import QueueComponent from './queueComponent/queue.component';

type QmsScreenProps = {
  siteId: string;
  isNested: boolean;
};

type QmsScreenState = {};

class QmsScreen extends React.Component<QmsScreenProps, QmsScreenState> {

  state: QmsScreenState = {};

  render(): any {
    return (
        <Container className={styles.rootQmsClass}>
          <div className={styles.container}>
            <QueueComponent siteId={this.props.siteId} isNested={this.props.isNested}/>
          </div>
        </Container>
    );
  }
}

export default QmsScreen;

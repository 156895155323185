// @ts-ignore
import notificationSound from './ui_new_order.mp3';
// @ts-ignore
import serverOfflineSound from './ui_error.mp3';
import {Howl} from 'howler';

const SERVER_OFFLINE_ALERT_DURATION_SEC = 30;

class SoundNotificationsV2 {

  static notificationSound: HTMLAudioElement = new Audio();
  static serverOfflineNotificationSound: Howl = new Howl({
    src: [serverOfflineSound],
    html5: false,
    loop: true,
    onplayerror: (soundId, error) => {
      console.error('Cannot play server offline alert');
      console.error(error);
    }
  }).load();
  static playNotificationSoundIndicator: boolean = false;
  static playServerOfflineIndicator: boolean = false;
  static serverOfflineSoundNotificationHandler?: number;
  static currentServerOfflineSoundRepeat: number = 0;

  static isInitialized: boolean = false;

  static initializeAudioFiles = () => {
    SoundNotificationsV2.notificationSound.src = notificationSound;
    SoundNotificationsV2.serverOfflineNotificationSound.play();
    SoundNotificationsV2.serverOfflineNotificationSound.mute(true);
    SoundNotificationsV2.isInitialized = true;
  }

  static playNotificationSound = () => {
    if (SoundNotificationsV2.playNotificationSoundIndicator) {
      return;
    }
    SoundNotificationsV2.playNotificationSoundIndicator = true;
    SoundNotificationsV2.notificationSound.src = notificationSound;
    SoundNotificationsV2.notificationSound.addEventListener('ended', SoundNotificationsV2.replayNotificationSoundOnEndCallback, false);
    SoundNotificationsV2.notificationSound.play();
    // .catch((error) => {
    //   console.error('Playback prevented:', error);
    // });
  }

  static playServerOfflineSoundWithDelay = () => {
    if (SoundNotificationsV2.isInitialized) {
      console.log('playServerOfflineSoundWithDelay');
      if (!SoundNotificationsV2.playServerOfflineIndicator) {
        console.log('playServerOfflineSoundWithDelay played');
        SoundNotificationsV2.playServerOfflineIndicator = true;
        SoundNotificationsV2.serverOfflineNotificationSound.mute(false);
        setTimeout(() => {
          SoundNotificationsV2.serverOfflineNotificationSound.mute(true);
          console.log('Muted after 30 sec');
        }, SERVER_OFFLINE_ALERT_DURATION_SEC * 1000);
      } else {
        console.log('playServerOfflineSoundWithDelay did not play');
      }
    }
  }

  static pauseNotificationSound = () => {
    SoundNotificationsV2.notificationSound.removeEventListener('ended', SoundNotificationsV2.replayNotificationSoundOnEndCallback);
    SoundNotificationsV2.notificationSound.pause();
    SoundNotificationsV2.playNotificationSoundIndicator = false;
  }

  static pauseServerOfflineSound = () => {
    console.log('pauseServerOfflineSound');
    if (SoundNotificationsV2.playServerOfflineIndicator) {
      SoundNotificationsV2.serverOfflineNotificationSound.mute(true);
    }
    SoundNotificationsV2.playServerOfflineIndicator = false;
    SoundNotificationsV2.currentServerOfflineSoundRepeat = 0;
    SoundNotificationsV2.serverOfflineSoundNotificationHandler = undefined;
  }

  static replayNotificationSoundOnEndCallback = () => {
    SoundNotificationsV2.notificationSound.currentTime = 0;
    setTimeout(() => SoundNotificationsV2.playNotificationSoundIndicator && SoundNotificationsV2.notificationSound.play(), 600);
  }
}

export default SoundNotificationsV2;

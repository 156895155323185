import React from 'react';
import {Button, Divider, Icon, List, Image, SemanticICONS} from 'semantic-ui-react';
import styles from './menuitems.module.css';
import feedback from '../../../../assets/announcement-24px.svg';
import {OrderStatus, PapOrder} from '../../../model/responses/paporder.response';
import {put} from '../../../clients/mobile-backend.client';
import {AxiosResponse} from 'axios';
import {ProductItems} from '../../productitems/productitems.component';
import CancelOrderModal from  '../cancelOrderModal/cancelOrderModal.component';
import Moment from 'react-moment';

type MenuItemProps = {
    order: PapOrder;
    siteId: string;
    itemId: string;
    getAndSetActivePreorders(siteId: string): void;
    removeFromList(id: string): void;
};

type MenuItemState = {
    printView: boolean;
    error: boolean;
    isCancelModalOpened: boolean;
    currentCancelOrder?: PapOrder;
};

class MenuItem extends React.Component<MenuItemProps, MenuItemState> {

    state: MenuItemState = {
        printView: false,
        error: false,
        isCancelModalOpened: false,
        currentCancelOrder: undefined
    };

    private componentRef: any = React.createRef();

    private cancelableStatuses: OrderStatus[] = [OrderStatus.NEW, OrderStatus.IN_PROGRESS, OrderStatus.READY];

    private nextStatus(currentStatus: string): any {
        switch (currentStatus) {
            case OrderStatus.NEW:
                return OrderStatus.IN_PROGRESS;
            case OrderStatus.IN_PROGRESS:
                return OrderStatus.READY;
            case OrderStatus.READY:
                return OrderStatus.PICKED_UP;
            case OrderStatus.PICKED_UP:
                return OrderStatus.PICKED_UP;
            case OrderStatus.CANCELLED:
                return OrderStatus.CANCELLED_IN_OCTANE;
            default:
                return undefined;
        }
    }

    private showPrintView(visibility: boolean): Promise<void> {
        return new Promise((resolve, reject) => {
            this.setState({ printView: visibility }, () => resolve());
        });
    }

    private changeStatus(orderId: string): Promise<any> {
        return put<any>(`/api/v2/orders/status/${orderId}`)
            .then((response: AxiosResponse) => {
                this.setState({
                    error: false
                });
                this.props.getAndSetActivePreorders(this.props.siteId);
            })
            .catch(response => {
                if (response.response.data.errorCode === 'NOT_ACCEPTABLE_TRANSITION') {
                    alert('Order was already cancelled by customer. Just skip this order');
                    this.props.getAndSetActivePreorders(this.props.siteId);
                } else {
                    this.setState({
                        error: true
                    });
                }
            });
    }

    private resolveStatusIcon(currentStatus: string): SemanticICONS {
        switch (currentStatus) {
            case OrderStatus.NEW:
                return 'clock outline';
            case OrderStatus.IN_PROGRESS:
                return 'refresh';
            case OrderStatus.READY:
                return 'shopping basket';
            case OrderStatus.CANCELLED:
                return 'times circle outline';
            case OrderStatus.CANCELLED_IN_OCTANE:
                return 'times circle outline';
            default:
                return 'clock outline';
        }
    }

    private resolveDetailsContainerTopStyle(currentStatus: string): string {
        switch (currentStatus) {
            case OrderStatus.NEW:
                return styles.DetailsContainerTopNew;
            case OrderStatus.IN_PROGRESS:
                return styles.DetailsContainerTopInProgress;
            case OrderStatus.READY:
                return styles.DetailsContainerTopReady;
            case OrderStatus.CANCELLED:
                return styles.DetailsContainerTopCancelled;
            case OrderStatus.CANCELLED_IN_OCTANE:
                return styles.DetailsContainerTopCancelled;
            default:
                return styles.DetailsContainerTop;
        }
    }

    private resolveMenuItemStyle(currentStatus: string): string {
        switch (currentStatus) {
            case OrderStatus.NEW:
                return styles.MenuItemNew;
            case OrderStatus.IN_PROGRESS:
                return styles.MenuItemInProgress;
            case OrderStatus.READY:
                return styles.MenuItemReady;
            case OrderStatus.CANCELLED:
                return styles.MenuItemCancelled;
            case OrderStatus.CANCELLED_IN_OCTANE:
                return styles.MenuItemCancelled;
            default:
                return styles.MenuItem;
        }
    }

    private resolveChangeStatusIcon(currentStatus: string): JSX.Element {
        switch (currentStatus) {
            case OrderStatus.CANCELLED:
                return <Icon name='check' />;
            case OrderStatus.CANCELLED_IN_OCTANE:
                return <Icon name='check' />;
            default:
                return <Icon name='exchange' />;
        }
    }

    private resolveChangeStatusLabel(currentStatus: string): string {
        switch (currentStatus) {
            case OrderStatus.CANCELLED:
                return 'Mark as corrected in POS';
            default:
                return 'Mark as ' + this.nextStatus(currentStatus);
        }
    }

    private resolveMenuItemContainerStyle(lessThanInMinutes: number, currentStatus: string): string {
        const diff: number = this.props.order.calculateDateTimeDifferenceInMinutes();
        return diff <= lessThanInMinutes && currentStatus === OrderStatus.NEW ? styles.MenuItemContainerFlashing : styles.MenuItemContainer;
    }

    private openCancelModal = (papOrder: PapOrder) => {
        this.setState({ isCancelModalOpened: true, currentCancelOrder: papOrder });
    }

    private closeCancelModal = () => {
        this.setState({ isCancelModalOpened: false, currentCancelOrder: this.props.order});
    }

    private removeFromList = () => {
        this.props.removeFromList(this.props.order.id);
    }

    render(): JSX.Element {
        let cancelButton, changeStatusButton;
        let orderStatus = this.props.order.status as keyof typeof OrderStatus;
        if (this.cancelableStatuses.includes(OrderStatus[orderStatus])) {
            cancelButton =
                <div className={styles.CancelButtonContainer}>
                    <button className={styles.CancelButton}
                            onClick={() => this.openCancelModal(this.props.order)}>
                        CANCEL THIS ORDER
                    </button>
                </div>;
        } else {
            cancelButton = <div></div>;
        }

        changeStatusButton = <p>
            <Button icon color='red' disabled={this.state.printView} labelPosition='left' onClick={() => this.changeStatus(this.props.order.id)}>
                {this.resolveChangeStatusIcon(this.props.order.status)}
                {this.resolveChangeStatusLabel(this.props.order.status)}
            </Button>
        </p>;

        return <div className={this.resolveMenuItemStyle(this.props.order.status)}>
            <CancelOrderModal
                order={this.props.order}
                isOpened={this.state.isCancelModalOpened}
                closeModal={this.closeCancelModal}
                removeFromList={this.removeFromList}
             />
            <div className={this.resolveMenuItemContainerStyle(15, this.props.order.status)}>
                <div className={styles.DetailsContainer}>
                    <div className={this.resolveDetailsContainerTopStyle(this.props.order.status)}>
                        <span className={styles.OrderHeaderText}><Icon name={this.resolveStatusIcon(this.props.order.status)}/>{this.props.order.status}</span>
                    </div>
                    <div className={styles.OrderHeader}>
                        <p className={styles.OrderInformation}>Order number / Pick up time</p>
                        <span className={styles.OrderNumber}>{this.props.order.checkNumber}</span>
                        <span className={styles.OrderTime}> / </span>
                        <span className={styles.OrderTime}><Moment format='HH:mm DD/MM'>{this.props.order.estimatedPickupTime}</Moment></span>
                        <div className={styles.ChangeStatus}>
                            {changeStatusButton}
                        </div>
                    </div>
                    {this.props.order.allergyNotes ? <div className={styles.AllergyNotes}>
                        <div className={styles.AllergyNotesHeader}>
                            Allergy note <div style={{ display: 'inline-block', paddingTop: '4px', width: '20px', height: '20px'}}>
                                <Image src={feedback}/>
                            </div>
                        </div>
                        <div className={styles.AllergyNotesText}>{this.props.order.allergyNotes}</div>
                    </div> : <Divider style={{ marginTop: '1px' }} />}
                    <div className={styles.DetailsList}>
                        <List divided>
                            <ProductItems items={this.props.order.products} styles={styles}/>
                        </List>
                    </div>
                    <Divider style={{ marginTop: '16px' }} />
                    <div className={styles.Summary}>
                        {this.props.order.user && this.props.order.user.name ?
                            <div style={{marginBottom: '15px'}}>
                                <p className={styles.SegmentTitleParagraph}>Name</p>
                                <p className={styles.SegmentContentParagraph}>{this.props.order.user?.name}</p>
                            </div> : null
                        }
                        <div>
                            <div style={{marginBottom: '15px'}}>
                                <p className={styles.SegmentTitleParagraph}>Pick up method</p>
                                <p className={styles.SegmentContentParagraph}>Pick up in store</p>
                            </div>
                        </div>
                        <div>
                            <div style={{marginBottom: '15px'}}>
                                <p className={styles.SegmentTitleParagraph}>Order placed via</p>
                                <p className={styles.SegmentContentParagraph}>{this.props.order.orderSource}</p>
                            </div>
                        </div>
                        {
                            this.props.order.user && this.props.order.user.phoneNumber ?
                                <div>
                                    <div style={{ marginBottom: '15px' }}>
                                        <p className={styles.SegmentTitleParagraph}>Phone number</p>
                                        <span className={styles.SegmentContentParagraph}>{this.props.order.user?.phoneNumber}</span>
                                        <span className={styles.PointerIcon} style={{ float: 'right' }}>
                                            <Icon name='phone' size='large' color='red' />
                                        </span>
                                    </div>
                                </div> : null
                        }
                        <div style={{ marginBottom: '15px' }}>
                            <p className={styles.SegmentTitleParagraph}>Order ID</p>
                            <span className={styles.SegmentContentParagraphSmall}>{this.props.order.longId}</span>
                            <span className={styles.PointerIcon} style={{ float: 'right' }}>
                                <Icon name='copy outline' size='large' color='red' />
                            </span>
                        </div>
                        {
                            this.props.order.comment ?
                                <div style={{ marginBottom: '15px' }}>
                                    <p className={styles.SegmentTitleParagraph}>Comment</p>
                                    <p className={styles.SegmentContentParagraph}>{this.props.order.comment}</p>
                                </div> : null
                        }
                    </div>
                    <Divider style={{ marginTop: '16px' }} />
                    {cancelButton}
                </div>
            </div>
        </div>;
    }

}

export default MenuItem;

import SoundNotificationsV2 from './soundNotificationsV2';

export class SoundNotificationManager {
  private orderSoundActive: boolean = false;
  private ariEventSoundActive: boolean = false;

  // Method to trigger the order sound
  public triggerOrderSound(): void {
    if (!this.orderSoundActive) {
      this.orderSoundActive = true;
      this.updateSoundStatus();
    }
  }

  // Method to stop the order sound
  public stopOrderSound(): void {
    if (this.orderSoundActive) {
      this.orderSoundActive = false;
      this.updateSoundStatus();
    }
  }

  // Method to trigger the ARI event sound
  public triggerAriEventSound(): void {
    if (!this.ariEventSoundActive) {
      this.ariEventSoundActive = true;
      this.updateSoundStatus();
    }
  }

  // Method to stop the ARI event sound
  public stopAriEventSound(): void {
    if (this.ariEventSoundActive) {
      this.ariEventSoundActive = false;
      this.updateSoundStatus();
    }
  }

  // Private method to update sound status based on current state of orders and ARI events
  private updateSoundStatus(): void {
    if (this.orderSoundActive || this.ariEventSoundActive) {
      // Play sound only if it's not already playing
      if (!SoundNotificationsV2.playNotificationSoundIndicator) {
        SoundNotificationsV2.playNotificationSound();
      }
    } else {
      // Pause sound only if it's currently playing
      if (SoundNotificationsV2.playNotificationSoundIndicator) {
        SoundNotificationsV2.pauseNotificationSound();
      }
    }
  }
}

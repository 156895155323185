import React, {useState} from 'react';
import {Icon, Label, Loader} from 'semantic-ui-react';
import styles from './activeorders.module.css';
import {PapOrder} from '../../model/responses/paporder.response';
import MenuItem from './menuitem/menuitem.component';
import {OrdersState} from '../dashboard/dashboard.component';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';
import 'react-horizontal-scrolling-menu/dist/styles.css';
import './hideScrollbar.css';
import {LeftArrow} from './leftArrow.component';
import {RightArrow} from './rightArrow.component';
import ClearAllOrdersModal from './clearAllOrdersModal/clearAllOrdersModal.component';

interface Props {
    siteId: string;
    ordersState: OrdersState;
    clearAllActiveOrders(): void;
    getAndSetActivePreorders(siteId: string): void;
    removeOrderFromActiveAndUpcomingOrdersList(id: string): void;
}


export const ActiveOrders = (props: Props) => {
    const [clearAllModalOpened, setClearAllModalOpened] = useState(false);

    const getLoader = () => {
        return (<Loader inline={'centered'} active size='tiny' content='Refreshing'/>);
    };

    const clearAllOrders = () => {
        if (haveOrders()) {
            openClearAllOrdersModal();
        }
    };

    const closeClearAllOrdersModal = () => {
        setClearAllModalOpened(false);
    };

    const openClearAllOrdersModal = () => {
        setClearAllModalOpened(true);
    };

    const clearActiveOrdersList = () => {
        props.clearAllActiveOrders();
    };

    const haveOrders = () => {
        return props.ordersState.orders.length > 0;
    };

    const getClearAllOrdersButtonStyles = () => {
        if (haveOrders()) {
            return [styles.QueueLabel, styles.PointerIcon].join(' ');
        } else {
            return [styles.QueueLabel, styles.DisabledButton].join(' ');
        }
    };

    const getActiveOrders = () => {
        return (<div>
            <ClearAllOrdersModal
                siteId={props.siteId}
                isOpened={clearAllModalOpened}
                closeModal={closeClearAllOrdersModal}
                clearActiveList={clearActiveOrdersList}
            />
            <div style={{display: 'flex', justifyContent: 'space-between', height: '60px'}}>
                <div></div>
                <div>
                    {props.ordersState.isLoading ? getLoader() : null}
                </div>
                <div className={styles.QueueLabelContainer}>
                    <Label size='large' onClick={clearAllOrders}
                           className={getClearAllOrdersButtonStyles()}
                           color={props.ordersState.orders.length > 0 ? 'green' : 'grey'}>
                        <Icon name='trash'/>Clear ALL Orders
                    </Label>
                    <Label size='large' className={styles.QueueLabel} color='orange'>
                        <Icon name='angle double right'/>{props.ordersState.orders.length} order(s) in queue
                    </Label>
                </div>
            </div>
            <div>
                <ScrollMenu
                    LeftArrow={<LeftArrow/>}
                    RightArrow={<RightArrow/>}
                >
                    {
                        props.ordersState.orders.map((order: PapOrder) => {
                            return <MenuItem order={order}
                                             itemId={order.id}
                                             key={order.id}
                                             siteId={props.siteId}
                                             getAndSetActivePreorders={props.getAndSetActivePreorders}
                                             removeFromList={() => props.removeOrderFromActiveAndUpcomingOrdersList(order.id)}/>;
                        })
                    }
                </ScrollMenu>
            </div>
        </div>);
    };

    const getActiveOrdersOrError = () => {
        const errorPargraph = <p
            style={{color: 'red', textAlign: 'center', fontWeight: 'bold', fontSize: '16px'}}
        >Error occurred during fetching orders.</p>;
        return (
            <React.Fragment>
                {props.ordersState.isError ? errorPargraph : getActiveOrders()}
            </React.Fragment>
        );
    };


    return (
        <div>
            {getActiveOrdersOrError()}
        </div>
    );
};

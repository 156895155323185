import styles from './clearAllOrdersModal.module.css';
import React from 'react';
import {Modal, Icon, Button} from 'semantic-ui-react';
import {post} from '../../../clients/mobile-backend.client';

interface Props {
  siteId: string;
  isOpened: boolean;
  closeModal: any;
  clearActiveList: any;
}

interface State {
  printView: boolean;
  isRequestRunning: boolean;
  clearAllOrdersErrorMessage: string;
}

class ClearAllOrdersModal extends React.Component<Props, State> {

  state: State = {
    printView: false,
    isRequestRunning: false,
    clearAllOrdersErrorMessage: ''
  };

  private closeModal(): void {
    this.props.clearActiveList();
    this.props.closeModal();
  }

  private clearAllOrders(): void {
    this.setState({isRequestRunning: true});
    post<any>(`/api/v2/orders/active/store/${this.props.siteId}/clear`, null)
    .then((res) => {
      this.closeModal();
    })
    .catch((response: { response: { data: { message: string } } }) => {
      this.setState({clearAllOrdersErrorMessage: response.response.data.message});
    })
    .finally(() => {
      this.setState({isRequestRunning: false});
    });
  }

  render(): any {
    const modalContent =
        <Modal.Content>
          <div className={[styles.ClearAllOrdersModalBorder, styles.ModalContentContainer].join(' ')}>
            <div className={styles.ClearAllOrdersModalTitleBackground}>
              <span className={styles.ClearAllOrdersHeaderText}>
                <Icon name={'clock outline'}/>
                CLEAR ALL ORDERS
              </span>
              <div className={[styles.TopRightIcon, styles.PointerIcon].join(' ')}>
                <Icon
                    size='big'
                    inverted
                    name='remove circle'
                    onClick={this.props.closeModal}
                />
              </div>
            </div>
            <div className={styles.ClearAllOrdersTextContainer}>
              <span
                  className={styles.ClearAllOrdersText}>Do you want to clear all active orders?</span>
              <br/>
              <span className={styles.ClearAllOrdersText}>Cleared orders are visible in orders history.</span>
            </div>
            <div className={styles.ClearAllOrdersErrorTextContainer}>
              <span
                  className={[styles.ClearAllOrdersText, styles.ClearAllOrdersErrorText].join(' ')}>
                {this.state.clearAllOrdersErrorMessage ? `ERROR: ${this.state.clearAllOrdersErrorMessage}` : null}
              </span>
            </div>
            <div className={styles.ButtonsRow}>
              <Button className={`${styles.Button} ${styles.CloseModalButton}`} basic circular
                      color='red' onClick={this.props.closeModal}>
                I CHANGED MY MIND
              </Button>
              <Button disabled={this.state.isRequestRunning}
                      className={`${styles.Button} ${styles.CancelOrderButton}`} circular
                      color='red' onClick={() => this.clearAllOrders()}>
                CLEAR ALL ORDERS
              </Button>
            </div>
          </div>
        </Modal.Content>;

    return (
        <Modal
            open={this.props.isOpened}
            basic
            closeOnDimmerClick={false}
        >
          {modalContent}
        </Modal>
    );
  }
}

export default ClearAllOrdersModal;

import React from 'react';

import {Icon} from 'semantic-ui-react';
import {Arrow} from './arrow.component';
import {VisibilityContext} from 'react-horizontal-scrolling-menu';

interface Props {
}

export const RightArrow = (props: Props) => {
  const {
    isLastItemVisible,
    scrollNext,
    visibleElements,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
      !initComplete || (initComplete && isLastItemVisible)
  );

  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isLastItemVisible);
    }
  }, [isLastItemVisible, visibleElements]);

  return (
      <Arrow disabled={isLastItemVisible}
             onClick={() => scrollNext()}>
        <Icon name='arrow right' size='large'/>
      </Arrow>
  );
};

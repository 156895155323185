import React from 'react';

import {Icon} from 'semantic-ui-react';
import {Arrow} from './arrow.component';
import {VisibilityContext} from 'react-horizontal-scrolling-menu';

interface Props {
}

export const LeftArrow = (props: Props) => {
  const {
    isFirstItemVisible,
    scrollPrev,
    visibleElements,
    initComplete
  } = React.useContext(VisibilityContext);

  const [disabled, setDisabled] = React.useState(
      !initComplete || (initComplete && isFirstItemVisible)
  );
  React.useEffect(() => {
    // NOTE: detect if whole component visible
    if (visibleElements.length) {
      setDisabled(isFirstItemVisible);
    }
  }, [isFirstItemVisible, visibleElements]);

  return (
      <Arrow disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        <Icon name='arrow left' size='large'/>
      </Arrow>
  );
};

import {AxiosError, AxiosResponse} from 'axios';
import moment from 'moment';
import {getFile, post} from '../../clients/mobile-backend.client';
import {saveAs} from 'file-saver';
import CancelOrderRequest from '../requests/cancelOrder.request';

export interface PapOrderResponse {
  id: string;
  status: string;
  checkNumber: string;
  checkSequenceNumber: string;
  createDate: string;
  datePlaced: string;
  estimatedPickupTime: string;
  orderSource: string;
  orderRefs: OrderRefs;
  payments: Payment[];
  storeId: string;
  orderClass: string;
  products: Product[];
  user: User;
  longId: string;
  allergyNotes: string | null;
  comment: string | null;
}

export enum PapOrderType  {
  CURRENT,
  UPCOMING
}

export class PapOrder {
  id: string;
  status: string;
  checkNumber: string;
  checkSequenceNumber: string;
  createDate: string;
  datePlaced: string;
  estimatedPickupTime: string;
  orderSource: string;
  orderRefs: OrderRefs;
  payments: Payment[];
  storeId: string;
  orderClass: string;
  products: Product[];
  user: User;
  longId: string;
  allergyNotes: string | null;
  comment: string | null;
  type: PapOrderType;

  constructor(id: string, longId: string, status: string, checkNumber: string, checkSequenceNumber: string,
              createDate: string, datePlaced: string, estimatedPickupTime: string, orderSource: string,
              orderRefs: OrderRefs, payments: Payment[], storeId: string, orderClass: string, products: Product[],
              user: User, allergyNotes: string | null, comment: string | null, type: PapOrderType) {
    this.id = id;
    this.longId = longId;
    this.status = status;
    this.checkNumber = checkNumber;
    this.checkSequenceNumber = checkSequenceNumber;
    this.createDate = createDate;
    this.datePlaced = datePlaced;
    this.estimatedPickupTime = estimatedPickupTime;
    this.orderSource = orderSource;
    this.orderRefs = orderRefs;
    this.payments = payments;
    this.storeId = storeId;
    this.orderClass = orderClass;
    this.products = products;
    this.user = user;
    this.allergyNotes = allergyNotes;
    this.comment = comment;
    this.type = type;
  }

  static from(papOrderResponses: PapOrderResponse[]): PapOrder[] {
    return papOrderResponses.map((response: PapOrderResponse) => this.mapSingleObject(response));
  }

  private static mapSingleObject(papOrderResponse: PapOrderResponse): PapOrder {
    return new PapOrder(
        papOrderResponse.id,
        papOrderResponse.longId,
        papOrderResponse.status,
        papOrderResponse.checkNumber,
        papOrderResponse.checkSequenceNumber,
        papOrderResponse.createDate,
        papOrderResponse.datePlaced,
        papOrderResponse.estimatedPickupTime,
        papOrderResponse.orderSource,
        papOrderResponse.orderRefs,
        papOrderResponse.payments,
        papOrderResponse.storeId,
        papOrderResponse.orderClass,
        papOrderResponse.products,
        papOrderResponse.user,
        papOrderResponse.allergyNotes,
        papOrderResponse.comment,
        PapOrder.resolvePapOrderType(papOrderResponse)
    );
  }

  private static resolvePapOrderType(papOrderResponse: PapOrderResponse): PapOrderType {
    const pickupDateTime: moment.Moment = moment(papOrderResponse.estimatedPickupTime);
    //to trim time
    //UPCOMING is order for tomorrow or later, not 24h after
    const dayAheadDateTime: moment.Moment = moment().add(1, 'days').startOf('day');
    if (pickupDateTime.isBefore(dayAheadDateTime)) {
      return PapOrderType.CURRENT;
    } else {
      return PapOrderType.UPCOMING;
    }
  }

  calculateDateTimeDifferenceInMinutes(): number {
    const pickupDateTime: moment.Moment = moment(this.estimatedPickupTime);
    const currentDateTime: moment.Moment = moment();
    return pickupDateTime.diff(currentDateTime, 'minutes');
  }

  public getPrintFile(): void {
    getFile<any>(`/api/v2/orders/print/${this.id}`)
      .then((response: AxiosResponse) => {
        var file = new File([response.data], `${this.id}-print.pdf`, {type: 'application/pdf'});
        saveAs(file);
      })
      .catch((error: AxiosError) => {
        alert(`Error generating PDF print\n\n:${error}`);
      });
  }

  public sendCancelRequest(): Promise<any> {
    const request: CancelOrderRequest = {
      id: this.id,
      storeId: this.storeId
    };
    return post<any>(`/api/v2/orders/cancelInPOS`, request);
  }

}

export interface OrderRefs {
  fo: string;
  'fo.integer': string;
}

export interface PaymentMethod {
  provider: string;
}

export interface Payment {
  amount: number;
  currency: string;
  paymentMethod: PaymentMethod;
  transactionId: string;
  paymentProviderReference: string;
}

export interface Vat {
  vatType: string;
  amount: number;
}

export interface Price {
  gross: number;
  net: number;
  vat: Vat;
  currency: string;
}

export interface UiProperties {
  desired: DesiredUiProperties;
}

export interface DesiredUiProperties {
  isNegated: boolean;
}

export interface Product {
  uuid: string | null;
  id: string;
  type: string;
  title: string;
  quantity: number;
  componentItems: ComponentItem[];
  uiProperties?: UiProperties | null;
  price?: any;
  notes: string | null;
}

export interface ComponentItem {
  uuid: string | null;
  code: string;
  name: string;
  option: ComponentItemOption;
  componentItems: ComponentItem[];
}

export enum ComponentItemOption {
  ADD = 'ADD',
  REMOVE = 'REMOVE',
}

export interface User {
  id: string;
  name: string;
  phoneNumber: string;
}

export enum OrderStatus {
  NEW = 'NEW',
  IN_PROGRESS = 'IN_PROGRESS',
  READY = 'READY',
  PICKED_UP = 'PICKED_UP',
  CANCELLED = 'CANCELLED',
  CANCELLED_IN_OCTANE = 'CANCELLED_IN_OCTANE'
}

import React from 'react';
import styles from './productstateerrormodal.module.css';
import {Icon, Modal} from 'semantic-ui-react';
import {Product} from '../../../model/Product';

interface Props {
  details?: Product | null;
  isOpened: boolean;
  closeModal: any;
}

interface State {
}

class ProductStateErrorModal extends React.Component<Props, State> {

  private modalContent = (props: Props): React.ReactNode => {
    return (
        <Modal.Content>
          <div className={[styles.BorderError, styles.DetailsContainer].join(' ')}>
            <div className={styles.BackgroundError}>
              <span className={styles.ErrorHeaderText}>
                <Icon name={'warning circle'}/>
                Error</span>
              <div className={[styles.DetailsContainerIcon, styles.PointerIcon].join(' ')}>
                <Icon
                    size='big'
                    inverted
                    name='remove circle'
                    onClick={props.closeModal}
                />
              </div>
            </div>
            <div className={styles.ErrorHeader}>
              <p className={styles.ErrorMessage}>Error occurred during update of stock state for:<br/>
                Product: <b>{props.details?.title}</b> <br/>
                Product ID: <b>{props.details?.productId}</b>
              </p>
              <p className={styles.ErrorMessage}>Please try again later.</p>
            </div>
          </div>
        </Modal.Content>
    );
  }

  render():  React.ReactNode {
        return (
        <Modal
            open={this.props.isOpened}
            basic
        >
          {this.props.details ? this.modalContent(this.props) : null}
        </Modal>
    );
  }

}


export default ProductStateErrorModal;

import React from 'react';
import {Button, Modal} from 'semantic-ui-react';
import QmsScreen from '../../qmsscreen/qmsscreen.component';
import styles from './qmsmodal.module.css';

type QmsModalProps = {
  siteId: string;
  isOpened: boolean;
  closeModal: any;
};

const QmsControl: React.FC<QmsModalProps> = (props: QmsModalProps) => {
  return (
      <Modal
          open={props.isOpened}
          className={styles.mainModal}
      >
        <Modal.Header>QMS preview</Modal.Header>
        <Modal.Content className={styles.previewContainer}>
          <QmsScreen siteId={props.siteId} isNested={true}/>
        </Modal.Content>
        <Modal.Actions>
          <Button color='red' onClick={() => {
            props.closeModal();
          }}>Close</Button>
        </Modal.Actions>
      </Modal>
  );
};

export default QmsControl;

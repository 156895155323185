import React from 'react';
import {
  Header,
  Segment,
  Table,
  Loader,
  Button,
  Grid,
  Icon,
  Divider,
  Dropdown,
  Message,
  List,
  Input,
  Container,
  InputOnChangeData, TableCell
} from 'semantic-ui-react';
import {SystemUserResponse} from '../../model/responses/systemuser.response';
import {AxiosResponse} from 'axios';
import {get, postMultipart, put, post} from '../../clients/mobile-backend.client';
import Dropzone, {DropzoneProps, DropzoneRootProps, DropzoneInputProps,  FileRejection} from 'react-dropzone';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import {SemanticDatepickerProps} from 'react-semantic-ui-datepickers/dist/types';
import moment from 'moment';
import PasswordModal from '../passwordmodal/passwordmodal.component';
import CreateQmsAccountModal from '../createqmsaccountmodal/createqmsaccountmodal';
import {QmsToken} from '../../model/responses/qmstoken';
import {QmsTokenResponse} from '../../model/responses/qmstokensresponse';
import {StoreConfig} from '../../model/responses/storeconfig';
import {StoreConfigResponse} from '../../model/responses/storeconfigresponse';

const accountStatusValues = [
  {key: 1, text: 'Enabled', value: true},
  {key: 2, text: 'Disabled', value: false}
];

type AdminState = {
  rejectedFiles: FileRejection[];
  accounts: SystemUserResponse[];
  accountsMap: Map<string, SystemUserResponse>;
  screenAccountsMap: Map<string, SystemUserResponse>;
  storeConfigs: Map<string, StoreConfig>;
  defaultConfig: StoreConfig | undefined;
  sitesError: boolean;
  configsError: boolean;
  tokensError: boolean;
  isLoadingSites: boolean;
  isLoadingConfigs: boolean;
  isLoadingQmsTokens: boolean;
  exportFrom: Date;
  exportTo: Date;
  exportStoreId: string;
  showStationPasswordChangeModal: boolean;
  showCreateQmsAccountModal: boolean;
  selectedUser?: SystemUserResponse;
  qmsTokens: QmsToken[];
  currentLink: string;
  qmsStores: string[];
};

class Admin extends React.Component<any, AdminState> {

  state: AdminState = {
    rejectedFiles: [],
    accounts: [],
    accountsMap: new Map<string, SystemUserResponse>(),
    screenAccountsMap: new Map<string, SystemUserResponse>(),
    storeConfigs: new Map<string, StoreConfig>(),
    defaultConfig: undefined,
    sitesError: false,
    configsError: false,
    tokensError: false,
    isLoadingSites: true,
    isLoadingConfigs: true,
    isLoadingQmsTokens: true,
    exportFrom: new Date(),
    exportTo: new Date(),
    exportStoreId: '',
    showStationPasswordChangeModal: false,
    showCreateQmsAccountModal: false,
    qmsTokens: [],
    currentLink: '',
    qmsStores: []
  };

  componentDidMount(): void {
    const currentUrl = window.location.href;
    this.setState({
      currentLink: currentUrl,
    });
    this.fetchAllStations();
    this.fetchAllQmsTokens();
    this.fetchAllStoreConfigs();
  }

  private fetchAllStations = () => {
    this.setState({isLoadingSites: true}, () => {
      get<SystemUserResponse[]>('/api/v2/users')
      .then((response) => {
        const sortedAccounts = response.data.sort((a, b) => a.username > b.username ? 1 : -1);
        const siteAccounts = sortedAccounts.filter(a => a.authorities.includes('ORDER_READ'));
        siteAccounts.forEach(a => this.state.accountsMap.set(a.username, a));
        const screenAccounts = sortedAccounts.filter(a => a.authorities.includes('SCREEN'));
        screenAccounts.forEach(a => {
          const relatedSiteId = a.username.replace('_screen', '');
          this.state.screenAccountsMap.set(relatedSiteId, a);
          const matchingSite = this.state.accountsMap.get(relatedSiteId);
          if (matchingSite) {
            matchingSite.hasScreenAccount = true;
          }
        });
        this.setState({
          accounts: siteAccounts,
          sitesError: false,
          isLoadingSites: false
        });
      })
      .catch((error: Error) => {
        this.setState({
          sitesError: true,
          isLoadingSites: false
        });
      });
    });
  }

  private fetchAllQmsTokens = () => {
    this.setState({isLoadingQmsTokens: true}, () => {
      get<QmsTokenResponse>('/api/v2/admin/store/qmsTokens')
      .then(response => {
        const queriedTokens = response.data.tokens;
        this.setState({
          qmsTokens: queriedTokens,
          isLoadingQmsTokens: false,
          tokensError: false,
          qmsStores: response.data.qmsSiteIds
        });
      })
      .catch((error: Error) => {
        this.setState({
          isLoadingQmsTokens: false,
          tokensError: true
        });
        console.log(error);
      });
    });
  }

  private fetchAllStoreConfigs = () => {
    this.setState({isLoadingSites: true}, () => {
      get<StoreConfigResponse>('/api/v2/admin/store/configs')
      .then(response => {
        const storeConfigResponse = response.data.storesConfig;
        const storesConfigMap = new Map<string, StoreConfig>();
        Object.keys(storeConfigResponse).forEach((storeId) => {
          storesConfigMap.set(storeId, storeConfigResponse[storeId]);
        });
        this.setState({
          isLoadingConfigs: false,
          configsError: false,
          storeConfigs: storesConfigMap
        });
      })
      .catch((error: Error) => {
        this.setState({
          isLoadingConfigs: false,
          configsError: true
        });
        console.log(error);
      });
    });
  }

  render(): React.ReactNode {
    return (
        <div>
          <Segment>
            <Header size='medium'>Add new stations</Header>
            {this.getDropzone()}
          </Segment>
          {this.getTableOrError()}
          <PasswordModal isOpened={this.state.showStationPasswordChangeModal}
                         selfUpdateMode={false}
                         isQms={this.state.selectedUser ? this.state.selectedUser.username.includes('screen') : false}
                         selectedUser={this.state.selectedUser ? this.state.selectedUser.username : ''}
                         closeModal={this.closeStationPasswordChangeModal}
          />
          <CreateQmsAccountModal isOpened={this.state.showCreateQmsAccountModal}
                                 selectedUser={this.state.selectedUser ? this.state.selectedUser.username : ''}
                                 closeModal={this.closeQmsCreateAccountModal}
          />
          {this.getQmsTokensTableOrError()}
          <Segment>
            <Header size='medium'>Export orders history</Header>
            {this.getExportOrders()}
          </Segment>
        </div>
    );
  }

  private handleRejectedFiles = () => {
    if (this.state.rejectedFiles.length) {
      return (
          <Message negative>
            <List>
              <List.Item>
                <List.Header as='h4'>Rejected files - invalid file format:</List.Header>
              </List.Item>
              {
                this.state.rejectedFiles.map(rejected => (
                    <List.Item key={rejected.file.name}>
                      <List.Icon name='close'/>
                      <List.Content>{rejected.file.name} - {rejected.file.size} bytes</List.Content>
                    </List.Item>
                ))
              }
            </List>
          </Message>
      );
    }
  }

  private getDropzone = (): React.ReactNode => {
    // @ts-ignore
    return <section>
      <div>
        <p>Drag 'n' drop CSV file here, or click to select file:</p>
        <Dropzone
            accept={{ 'text/csv': ['.csv'] }}
            multiple={false}
            onDrop={(acceptedFiles: File[], fileRejections: any) => {
              this.upload(acceptedFiles);
              this.setState({rejectedFiles: fileRejections});
            }}>
          {({ getRootProps, getInputProps }: { getRootProps: () => DropzoneRootProps; getInputProps: () => DropzoneInputProps }) => (
              <span {...getRootProps()}>
            <input {...getInputProps()} />
            <Button icon color='red' labelPosition='left'><Icon name='upload'/>Upload</Button>
          </span>
          )}
        </Dropzone>
        <Divider/>
        <p>Sites included in CSV file will be added to the database.</p>
        <p>After a successful operation you will be able to download a list of sites with credentials.</p>
        {this.handleRejectedFiles()}
      </div>
    </section>;
  }

  private upload = (acceptedFiles: File[]) => {
    acceptedFiles.forEach(file => {
      postMultipart<any>('/api/v2/users/batch-create', file, null, 'blob')
      .then((response: AxiosResponse) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.target = '_blank';
        link.download = 'file-with-passwords.txt';
        link.click();
        this.fetchAllStations();
      })
      .catch((error: Error) => {
        this.setState({
          isLoadingSites: false
        });
      });
    });
  }

  private getLoader = () => {
    return (<div style={{margin: '0% 50%'}}>
      <Loader active inline content='Loading'/>
    </div>);
  }

  private getTableOrError = () => {
    const sitesError = <p
        style={{color: 'red', textAlign: 'center', fontWeight: 'bold', fontSize: '16px'}}>
      Error occurred during fetching stations.
    </p>;
    const configError = <p
        style={{color: 'red', textAlign: 'center', fontWeight: 'bold', fontSize: '16px'}}>
      Error occurred during fetching QMS enablement in stations.
    </p>;
    return (
        <React.Fragment>
          {!this.state.sitesError && !this.state.configsError && !this.state.tokensError ? this.getTable() : undefined}
          {this.state.sitesError  ? sitesError : undefined}
          {this.state.configsError ? configError : undefined}
        </React.Fragment>
    );
  }

  private getExportOrders = () => {
    return (
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Container>
                <p>Station ID:</p>
                <Input onChange={(event, data) => this.handleInputStationId(data)}/>
              </Container>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={4}>
            <Grid.Column>
              <p>From:</p>
              <SemanticDatepicker id='datepickerFrom'
                                  onChange={(event, data) => this.handleDatepickerFrom(data)}
                                  value={this.state.exportFrom}/>
            </Grid.Column>
            <Grid.Column>
              <p>To:</p>
              <SemanticDatepicker id='datepickerTo'
                                  onChange={(event, data) => this.handleDatepickerTo(data)}
                                  value={this.state.exportTo}/>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <Button icon color='red' labelPosition='left'
                      onClick={() => this.handleExport()}><Icon name='download'/>Export</Button>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row columns={1}>
            <Grid.Column>
              <p>CSV file with list of orders from specified date range will be exported.</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
    );
  }

  private handleInputStationId = (data: InputOnChangeData) => {
    this.setState({
      exportStoreId: data.value
    });
  }

  private handleDatepickerFrom = (data: SemanticDatepickerProps) => {
    this.setState({
      exportFrom: data.value as Date
    });
  }

  private handleDatepickerTo = (data: SemanticDatepickerProps) => {
    this.setState({
      exportTo: data.value as Date
    });
  }

  private handleExport = () => {
    const dateFormat: string = 'YYYY-MM-DD';
    const formattedFrom: string = moment(this.state.exportFrom).format(dateFormat);
    const formattedTo: string = moment(this.state.exportTo).format(dateFormat);
    const body: any = {
      startDate: formattedFrom,
      endDate: formattedTo,
      storeId: this.state.exportStoreId
    };

    post<any>('/api/v2/orders/export', body, null, 'blob')
    .then((response: AxiosResponse) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([response.data]));
      link.target = '_blank';
      link.download = `orders_${this.state.exportStoreId}_from_${formattedFrom}_to_${formattedTo}.csv`;
      link.click();
    })
    .catch((error: Error) => {
      this.setState({
        sitesError: true
      });
    });
  }

  private renderCell = (content: any, disabledCell: boolean = false): React.ReactNode => {
    return (
        <Table.Cell disabled={disabledCell}>
          {content}
        </Table.Cell>
    );
  }

  private renderCellWithBoolean = (content: boolean): React.ReactNode => {
    return (
        <Table.Cell>
          {content ? 'HAS QMS' : undefined}
        </Table.Cell>
    );
  }

  private renderCellWithActiveStatusOfAccount = (user: SystemUserResponse): React.ReactNode => {
    return this.renderCell(
        <Dropdown
            text={this.resolveEnabledText(user.enabled)}
            options={accountStatusValues}
            closeOnChange
            onChange={() => this.handleAccountStatusChange(user)}
            value={user.enabled}
        />
    );
  }

  private renderCellWithQMSStatusOfStore = (storeId: string, qmsEnabled: boolean): React.ReactNode => {
    return this.renderCell(
        <Dropdown
            text={this.resolveEnabledText(qmsEnabled)}
            options={accountStatusValues}
            closeOnChange
            onChange={() => this.handleQmsStatusChange(qmsEnabled, storeId)}
            value={qmsEnabled}
        />
    );
  }

  private renderCellWithActiveStatusOfQmsToken = (qmsToken: QmsToken): React.ReactNode => {
    return this.renderCell(
        <Dropdown
            text={this.resolveEnabledText(qmsToken.active)}
            options={accountStatusValues}
            closeOnChange
            onChange={() => this.handleQmsTokenStatusChange(qmsToken)}
            value={qmsToken.active}
        />,
        !this.state.qmsStores.includes(qmsToken.storeId)
    );
  }

  private renderCellWithPasswordChangeButton = (user: SystemUserResponse): React.ReactNode => {
    return this.renderCell(
        <div>
          <Button style={{width: '190px'}} color='red' content='Change password (SOM)'
                  onClick={() => this.openStationPasswordChangeModal(user)}/>
          {user.hasScreenAccount ?
              <Button style={{width: '190px'}} color='green' content='Change password (QMS)'
                      onClick={() => this.openStationPasswordChangeModal(
                          this.state.screenAccountsMap.get(user.username)
                      )}/>
              :
              <Button style={{width: '190px'}} color='green' content='Create QMS account'
                      onClick={() => this.openCreateQmsAccountModal(user)}/>
          }
        </div>
    );
  }

  private openStationPasswordChangeModal = (user: SystemUserResponse | undefined) => {
    if (user) {
      this.setState({showStationPasswordChangeModal: true, selectedUser: user});
    }
  }

  private openCreateQmsAccountModal = (user: SystemUserResponse | undefined) => {
    if (user) {
      this.setState({showCreateQmsAccountModal: true, selectedUser: user});
    }
  }

  private closeStationPasswordChangeModal = () => {
    this.setState({showStationPasswordChangeModal: false});
  }

  private closeQmsCreateAccountModal = () => {
    this.setState({showCreateQmsAccountModal: false}, () => {
      this.fetchAllStations();
    });
  }

  private resolveEnabledText = (enabled: boolean): any => {
    // @ts-ignore
    return _.find(accountStatusValues, (accountStatusValue) => {
      return accountStatusValue.value === enabled;
    }).text;
  }

  private handleAccountStatusChange = (user: SystemUserResponse): void => {
    const body = {'enabled': !user.enabled};
    put<any>(`/api/v2/users/${user.username}/availability`, null, body)
    .then((response: AxiosResponse) => {
      this.setState({
        sitesError: false,
        isLoadingSites: false
      });
      this.fetchAllStations();
    })
    .catch((error: Error) => {
      this.setState({
        sitesError: true,
        isLoadingSites: false
      });
    });
  }

  private handleQmsStatusChange = (currentValue: boolean, storeId: string): void => {
    const action = currentValue ? 'disable' : 'enable';
    put<any>(`/api/v2/admin/store/${storeId}/qms/${action}`, null)
    .then((response: AxiosResponse) => {
      const configMap = this.state.storeConfigs;
      const currentStore = this.state.storeConfigs.get(storeId) || new StoreConfig(storeId, currentValue);
      currentStore.isQmsEnabled = !currentValue;
      configMap.set(storeId, currentStore);
      this.setState({
        configsError: false,
        isLoadingConfigs: false,
        storeConfigs: configMap
      });
      this.fetchAllStations();
      this.fetchAllQmsTokens();
    })
    .catch((error: Error) => {
      this.setState({
        configsError: true,
        isLoadingConfigs: false
      });
    });
  }

  private handleQmsTokenStatusChange = (qmsToken: QmsToken): void => {
    const body = {'active': !qmsToken.active};
    put<any>(`/api/v2/store/qmsToken/${qmsToken.id}`, null, body)
    .then((response: AxiosResponse) => {
      this.setState({
        tokensError: false,
        isLoadingQmsTokens: false
      });
      this.fetchAllQmsTokens();
    })
    .catch((error: Error) => {
      this.setState({
        tokensError: true,
        isLoadingQmsTokens: false
      });
    });
  }

  private getTable = (): React.ReactNode => {
    let mainContent;
    if (this.state.isLoadingSites) {
      mainContent = <Table.Body>
        <Table.Row>
          <TableCell colSpan='3'>
            {this.getLoader()}
          </TableCell>
        </Table.Row>
      </Table.Body>;
    } else {
      mainContent = <Table.Body>
        {this.state.accounts.map((account: SystemUserResponse) => {
          let qmsEnabled = false;
          let storeConfig = this.state.storeConfigs.get(account.username);
          let defaultConfig = this.state.defaultConfig;
          if (storeConfig !== undefined) {
            qmsEnabled = storeConfig.isQmsEnabled;
          }
          if (defaultConfig !== undefined) {
            qmsEnabled = defaultConfig.isQmsEnabled;
          }
          return (
              <Table.Row key={account.username}>
                {this.renderCell(account.username)}
                {this.renderCellWithActiveStatusOfAccount(account)}
                {this.renderCellWithQMSStatusOfStore(account.username, qmsEnabled)}
                {this.renderCellWithPasswordChangeButton(account)}
              </Table.Row>
          );
        })}
      </Table.Body>;
    }
    return <Segment>
      <Grid>
        <Grid.Column floated='left' width={5}>
          <Header size='medium'>Existing stations</Header>
        </Grid.Column>
        <Grid.Column floated='right'>
          <Button basic circular icon='refresh' size='mini' onClick={() => {
            this.fetchAllStations();
          }}
          />
        </Grid.Column>
      </Grid>
      <Table singleLine striped selectable sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={3}>Site ID</Table.HeaderCell>
            <Table.HeaderCell width={3}>Account status</Table.HeaderCell>
            <Table.HeaderCell width={2}>QMS status</Table.HeaderCell>
            <Table.HeaderCell width={8}>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {mainContent}
      </Table>
    </Segment>;
  }

  private copyToClipBoard = (content: string): void => {
    navigator.clipboard.writeText(content).then(() => {
    }).catch(err => {
      console.error('Error copying text: ', err);
    });
  }

  private getQmsTokensTableOrError = (): React.ReactNode => {
    const errorParagraph = <p
        style={{color: 'red', textAlign: 'center', fontWeight: 'bold', fontSize: '16px'}}>
      Error occurred during fetching QMS Tokens.
    </p>;
    return (
        <React.Fragment>
          {this.state.tokensError ? errorParagraph : this.getQmsTokensTable()}
        </React.Fragment>
    );
  }

  private getQmsTokensTable = (): React.ReactNode => {
    let mainContent;
    if (this.state.isLoadingQmsTokens) {
      mainContent = <Table.Body>
        <Table.Row>
          <TableCell colSpan='3'>
            {this.getLoader()}
          </TableCell>
        </Table.Row>
      </Table.Body>;
    } else {
      mainContent = <Table.Body>
        {this.state.qmsTokens.map((qmsToken: QmsToken) => {
          const qmsLink = this.state.currentLink + 'cktv?id=' + qmsToken.id;
          return (
              <Table.Row key={qmsToken.id}>
                {this.renderCell(qmsToken.storeId)}
                {this.renderCellWithActiveStatusOfQmsToken(qmsToken)}
                {this.renderCell(qmsLink)}
                <Table.Cell textAlign='right'>
                  <Button basic icon='copy' size='mini' onClick={() => {
                    this.copyToClipBoard(qmsLink);
                  }}
                  />
                </Table.Cell>
              </Table.Row>
          );
        })}
      </Table.Body>;
    }

    return <Segment>
      <Grid>
        <Grid.Column floated='left' width={5}>
          <Header size='medium'>QMS tokens</Header>
        </Grid.Column>
        <Grid.Column floated='right'>
          <Button basic circular icon='refresh' size='mini' onClick={() => {
            this.fetchAllQmsTokens();
          }}
          />
        </Grid.Column>
      </Grid>
      <Table.Row>
        Enabled for stores: {this.state.qmsStores.join(', ')}.
        You cannot enable token for disabled store.
      </Table.Row>
      <Table singleLine striped selectable sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Site ID</Table.HeaderCell>
            <Table.HeaderCell width={2}>Token status</Table.HeaderCell>
            <Table.HeaderCell width={10}>QMS Link</Table.HeaderCell>
            <Table.HeaderCell width={2}>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        {
          mainContent
        }
      </Table>
    </Segment>;
  }

}

export default Admin;

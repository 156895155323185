import React, {useEffect, useState} from 'react';
import styles from './queue.module.css';
import parentStyles from '../qmsscreen.module.css';
import {Icon} from 'semantic-ui-react';
import {get, getNoBasicAuth} from '../../../clients/mobile-backend.client';
import {PapOrderResponse} from '../../../model/responses/paporder.response';
import {AxiosResponse} from 'axios';
import OrderBlock from './orderblock/orderblock.component';
import {useLocation} from 'react-router-dom';
import FadeOutControllers from '../fadeOutControlers/fadeoutcontrollers.component';

type QueueComponentProps = {
  siteId: string;
  isNested: boolean;
};

type QueueComponentState = {
  siteId: string;
  waitingNumbers: string[];
  readyNumbers: string[];
  qmsToken: string;
};

const QueueComponent: React.FC<QueueComponentProps> = (props: QueueComponentProps) => {
  // Initialize state using useState hook
  const location = useLocation();

  // Function to get query parameter value by name
  const getQueryParam = (name : string, defaultValue : string | null) => {
    return new URLSearchParams(location.search).get(name) || defaultValue;
  };

  const qmsToken = getQueryParam('id', null);
  const headerSize = getQueryParam('headerSize', '100');
  const orderSize = getQueryParam('orderSize', '100');
  const headerBase = getQueryParam('headerBase', '2.8');
  const orderBase = getQueryParam('orderBase', '6.0');

  const [state, setState] = useState<QueueComponentState>({
    waitingNumbers: [],
    readyNumbers: [],
    qmsToken: '',
    siteId: ''
  });

  //on load
  useEffect(() => {
    const intervalId = setInterval(loadOrders, 5000);

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  });

  const loadOrders = (): void => {
    if (qmsToken) {
      loadOrderIdsByQmsToken();
    } else {
      loadOrderIdsByBasicAuth();
    }
  };

  const sortOrdersByOrderReadyTime = (aOrder: any, bOrder: any) => {
    const aDate = new Date(aOrder.readyDate);
    const bDate = new Date(bOrder.readyDate);
    if (aDate && bDate) {
      const aDateValue = aDate.valueOf();
      const bDateValue = bDate.valueOf();
      return aDateValue - bDateValue;
    } else if (!aDate && !bDate) {
      return 0;
    } else if (aDate) {
      return 1;
    } else {
      return -1;
    }
  };

  const sortOrdersByCreateDate = (a: any, b: any) => {
    const aDate = new Date(a.createDate);
    const bDate = new Date(b.createDate);
    const aDateValue = aDate.valueOf();
    const bDateValue = bDate.valueOf();
    return aDateValue - bDateValue;
  };

  const loadOrderIdsByBasicAuth = (): void => {
    get<PapOrderResponse[]>(`/api/v2/orders/active/store/${props.siteId}`)
    .then((response: AxiosResponse) => {
      let waitingArrObj = response.data.filter((order : any) => isWaiting(order))
        .sort(sortOrdersByCreateDate);
      let waitingArr = waitingArrObj
        .map((order : any) => order.checkNumber);
      let readyArr = response.data.filter((order : any) => isReady(order))
        .sort(sortOrdersByOrderReadyTime)
        .map((order : any) => order.checkNumber);
      setState({...state, waitingNumbers: waitingArr, readyNumbers: readyArr, siteId: props.siteId});
    }).catch(e => {
      console.error(e);
      setState({...state, waitingNumbers: ['NO CONNECTION'], readyNumbers: ['NO CONNECTION']});
    });
  };

  const loadOrderIdsByQmsToken = (): void => {
    getNoBasicAuth<PapOrderResponse[]>(`/api/v2/orders/active/qms?id=${qmsToken}`)
    .then((response: AxiosResponse) => {
      let orders, storeId;
      if (response.data.orders) {
        orders = response.data.orders;
        storeId = response.data.storeId;
      } else {
        orders = response.data;
        storeId = response.data ? response.data[0] : '';
      }
      let waitingArrObj = orders.filter((order : any) => isWaiting(order))
      .sort(sortOrdersByCreateDate);
      let waitingArr = waitingArrObj
      .map((order : any) => order.checkNumber);
      let readyArr = orders.filter((order : any) => isReady(order))
      .sort(sortOrdersByOrderReadyTime)
      .map((order : any) => order.checkNumber);
      setState({...state, waitingNumbers: waitingArr, readyNumbers: readyArr, siteId: storeId});
    }).catch(e => {
      console.error(e);
      setState({...state, waitingNumbers: ['NO CONNECTION'], readyNumbers: ['NO CONNECTION']});
    });
  };

  const isWaiting = (order : any): boolean => {
    return order.status === 'IN_PROGRESS';
  };

  const isReady = (order : any): boolean => {
    return order.status === 'READY';
  };

  const showLogoutButton = () : boolean => {
    return !qmsToken && !props.isNested;
  };

  // sites starting from 10 are from Denmark
  const preparingHeaderTitle = () : string => {
    return isDanishSite() ? 'Tilberedes' : 'Preparing';
  };

  const readyHeaderTitle = () : string => {
    return isDanishSite() ? 'Klar' : 'Ready';
  };

  const isDanishSite = () : boolean => {
    try {
      return state.siteId.startsWith('10') || (props.siteId && props.siteId.trim().startsWith('10')) as boolean;
    } catch (ex) {
      return false;
    }
  };

  return (
      <div className={`${parentStyles.column} ${parentStyles.flex1}`}>
        {showLogoutButton() ? <FadeOutControllers/> : undefined}
        <div className={`${parentStyles.container}`}>
          <div className={`${parentStyles.column} ${parentStyles.flex1} ${styles.oneSideContainer}`} style={{backgroundColor: '#f8f8f8'}}>
            <div className={`${styles.orderTypeHeader} ${styles.waitingHeader}`}
              style={{fontSize: (Math.round( Number(headerBase) * Number(headerSize)) / 100) + 'vh'}}>
              <Icon name='refresh'/>{preparingHeaderTitle()}
            </div>
            <div className='ui divider'></div>
            <div className={`${styles.twoColumnNumberList}`}>
              {state.waitingNumbers.map((item, index) => (
                  <OrderBlock itemId={item} size={Math.round( Number(orderSize) * Number(orderBase)) / 100} key={index}/>
              ))}
            </div>
          </div>
          <div className={`${parentStyles.column} ${parentStyles.flex1} ${styles.oneSideContainer}`} style={{backgroundColor: '#ed8b00'}}>
            <div className={`${styles.orderTypeHeader} ${styles.readyHeader}`}
                 style={{fontSize: (Math.round( Number(headerBase) * Number(headerSize)) / 100) + 'vh'}}>
              <Icon name='shopping basket'/>{readyHeaderTitle()}
            </div>
            <div className='ui divider'></div>
            <div className={`${styles.twoColumnNumberList}`}>
              {state.readyNumbers.map((item, index) => (
                  <OrderBlock itemId={item} size={Math.round( Number(orderSize) * Number(orderBase)) / 100} key={index}/>
              ))}
            </div>
          </div>
        </div>
      </div>
  );
};

export default QueueComponent;

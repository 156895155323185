import React from 'react';
import QmsScreen from './qmsscreen.component';

type QmsScreenNoPropsProps = {
};

type QmsScreenNoPropsState = {};

class QmsScreenNoProps extends React.Component<QmsScreenNoPropsProps, QmsScreenNoPropsState> {

  state: QmsScreenNoPropsState = {};

  render(): any {
    return (
        <QmsScreen siteId={'0'} isNested={false}/>
    );
  }
}

export default QmsScreenNoProps;

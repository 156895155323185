import React from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Layout from './layout/layout.component';
import QmsScreenNoProps from './qmsscreen/qmsscreennoprops.component';

class Application extends React.Component {

  render(): React.ReactNode {
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path='/' element={<Layout/>} />
          <Route path='/cktv' element={<QmsScreenNoProps/>} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default Application;

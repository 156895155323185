import React from 'react';
import axios, {AxiosError, AxiosResponse} from 'axios';
import styles from './footer.module.css';
import SoundNotificationsV2 from '../activeorders/soundNotificationsV2';

type State = {
  appVersion: string | null;
  isError: boolean;
};

const browserWindow: any = window || {};
const browserWindowEnv: any = browserWindow['_env_'] || {};

const baseUrl: string = browserWindowEnv.REACT_APP_MB_BASE_URL;
const guiVersion: string = browserWindowEnv.REACT_APP_VERSION;

export class FooterComponent extends React.Component<any, State> {

  private severRefreshHandler?: number;

  state: State = {
    appVersion: null,
    isError: false
  };

  componentDidMount(): void {
    this.getServerStatus();
    this.severRefreshHandler = window.setInterval(() => this.getServerStatus(), 30000);
  }

  componentWillUnmount(): void {
    clearInterval(this.severRefreshHandler);
  }

  private getServerStatus = () => {
    axios.get(`${baseUrl}/api/v2/version`)
    .then((response: AxiosResponse) => {
      this.setState({appVersion: response.data.app_version, isError: false});
      this.turnServerOfflineSoundNotificationOff();
    })
    .catch((error: AxiosError) => {
      this.setState({appVersion: null, isError: true});
      this.turnServerOfflineSoundNotificationOn();
    });
  }

  render(): JSX.Element {
    const serverStatus = this.state.isError ? <span style={{color: 'red'}}>Error</span> : <span style={{color: 'green'}}>OK</span>;

    return (
        <div className={styles.Footer}>
          <span >Circle K {new Date().getFullYear().toString()} |
            Server status: {serverStatus} | GUI version: {guiVersion} |  Server version: {this.state.appVersion}
          </span>
        </div>
    );
  }

  private turnServerOfflineSoundNotificationOff = () => {
    SoundNotificationsV2.pauseServerOfflineSound();
  }

  private turnServerOfflineSoundNotificationOn = () => {
    SoundNotificationsV2.playServerOfflineSoundWithDelay();
  }

}

